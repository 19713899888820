









































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BPagination, BSpinner, VBPopover, BButton } from 'bootstrap-vue';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { successAlert } from "@/libs/sweetAlerts/alerts";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import { ActionsPreventionSantePublique } from '@/api/models/preventionSantePublique/actionPreventionSantePublique';

@Component({
  name: 'list-actions-prevention-sante-publique',
  components: {
    BPagination,
    BSpinner,
    BButton,
    DeleteIcon,
    EditIcon
  },
  directives: {
    'b-popover': VBPopover
  }
})
export default class ListActionsPreventionSantePublique extends Vue {
  droitsEnum = DroitsEnum
  
  @Prop({ required: true }) projetId!: string
  
  currentPage = 1;
  perPage = 5;

  paginatedList : PaginatedList<ActionsPreventionSantePublique> = new PaginatedList<ActionsPreventionSantePublique>()

  listLoading = true

  async created() {
    await this.loadActions();
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadActions()
  }

  async loadActions() {
    this.listLoading = true;
    
    await this.$http.ressifnet.actionsPreventionSantePublique
      .paginatedList(this.$route.params.idEntreprise, this.projetId, this.currentPage, this.perPage)
      .then(
        (res: PaginatedList<ActionsPreventionSantePublique>) => {
          this.paginatedList = res;
          this.listLoading = false
        })
  }

  async deleteAction(id: string) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer l\'action ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDeleteAction(id)
          } 
          else {
            return
          }
        })
  }

  async confirmDeleteAction(id: string) {
    await this.$http.ressifnet.actionsPreventionSantePublique
      .delete(this.$route.params.idEntreprise, this.projetId, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une action",
            text: "Suppression effectuée avec succès",
          });
          this.loadActions()
          this.$emit('reset-form')
        }
      )
  }

  async selectAction(id: string, mode?:any) {
    this.$emit('select-action', id, mode)
  }
}
