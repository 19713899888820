











import { BFormFile } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { 
    BFormFile
  },
  name: "multiple-file-input",
})
export default class MultipleFileInput extends Vue {
  @Prop({ required: true }) value: any
  @Prop({ required: false, default: false }) disabled: any

  onFileChange(event: any) {
    const files = Array.from(event.target.files);
    this.value?.push(...files);
    event.target.value = '';
  }
}

