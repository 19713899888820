












































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ListActionsPreventionSanteTravail from './_ListActions.vue';
import { PaginatedList } from '@/api/models/common/paginatedList';
import {
  BButton, BFormInput, BInputGroupAddon, BInputGroupAppend, BInputGroupPrepend, BInputGroupText, BSpinner, BFormCheckbox
} from 'bootstrap-vue'
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { Theme } from '@/api/models/options/themes/theme';
import { Site } from '@/api/models/options/entreprises/site';
import { BFormSelect, BFormSelectOption, } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { ProjetPreventionSanteTravail, ProjetPreventionSanteTravailCreateModel } from '@/api/models/preventionSanteTravail/projetPreventionSanteTravail';
import { ThemePreventionSanteTravail } from '@/api/models/preventionSanteTravail/themePreventionSanteTravail';
import CreateActionPreventionSanteTravail from './_CreateAction.vue';

@Component({
  components: {
    ListActionsPreventionSanteTravail,
    CreateActionPreventionSanteTravail,
    BButton,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    BInputGroupAddon,
    SearchableVueSelect,
    RessifnetDateInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    RessifnetQuillEditor,
    BSpinner
  }
})
export default class AccueilPreventionSanteTravail extends Vue {
  droitsEnum = DroitsEnum;

  loadingUpdate: boolean = false
  actionId: any = "0"
  refreshIndex = 0 ;
  allEntreprises = false

  selectedProjet: ProjetPreventionSanteTravail | null = null
  newProjet: ProjetPreventionSanteTravailCreateModel = {
    allEntreprises: false
  }
  clearModel : ProjetPreventionSanteTravailCreateModel = {
    allEntreprises: false
  }

  allEntreprise = false;

  projets: PaginatedList<ProjetPreventionSanteTravail> = new PaginatedList<ProjetPreventionSanteTravail>()

  // Create projets
  listThemes: ThemePreventionSanteTravail[] = []
  listSites: Site[] = []
  totalCountSites: number = 0

  editMode = false

  async created() {
    await this.loadListThemes()
  }

  async createProjet(data: ProjetPreventionSanteTravailCreateModel) {
    await this.$http.ressifnet.projetsPreventionSanteTravail.post(this.$route.params.idEntreprise, data)
      .then(async (response: any) => {
        await this.$http.ressifnet.projetsPreventionSanteTravail.getById(this.$route.params.idEntreprise, response)
          .then((newProj: ProjetPreventionSanteTravail) => {
            this.selectProjet(newProj)
            this.selectedProjet = newProj
            if(this.selectedProjet.sitesId!.length = 0) this.allEntreprise = true;
            this.$bvModal.hide('projetModal')
            successAlert.fire({
              title: 'Ajout d\'un projet',
              text: 'Création effectuée avec succès',
            })
            this.newProjet = {}
          })
      }, (error: any) => {
        errorAlert.fire({
          title: "Erreur lors de la création du projet",
        });
      });
  }

  async searchProjets(params: any) {
    if (!params || params?.reset) {
      this.projets = new PaginatedList<ProjetPreventionSanteTravail>()
    }

    await this.$http.ressifnet.projetsPreventionSanteTravail.paginatedList(this.$route.params.idEntreprise, 1, 10, params?.search ?? "").then(
      (response: PaginatedList<ProjetPreventionSanteTravail>) => {
        this.projets = response;
        // this.selectedProjet = this.projets.items.length === 1 ? this.projets.items[0] : null;
        // if(this.selectedProjet) this.searchSites(null);
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des projets",
        });
      }
    );
  }

  async updateProjet() {
    this.loadingUpdate = true
    await this.$http.ressifnet.projetsPreventionSanteTravail.put(this.$route.params.idEntreprise, this.selectedProjet?.id, this.selectedProjet)
      .then(async (response: string) => {
        successAlert.fire({
          title: "Modification d\'un projet",
          text: "Modification effectuée avec succès"
        })
        this.editMode = false
        this.loadingUpdate = false
      }, (error: any) => {
        if (error.response?.data?.detail && error.response.data.detail.length > 0) {
          errorAlert.fire({
            text: error.response.data.detail
          })
        } else {
          errorAlert.fire({
            text: error.message
          })
        }
      })
  }

  setEditMode() {
    this.editMode = true
  }

  async selectProjet(val: ProjetPreventionSanteTravail) {
    this.listSites = [];
    if (val.sitesId) {
      val.sitesId.forEach((e: any) => {
        this.listSites.push({
          id: e,
          libelle: val.sitesLibelle[e - 1],
          regionId: "",
          regionLibelle: "",
          brancheId: ""
        })
      })
      await this.searchSites(null);
    }
    else {
      await this.searchSites(null)
    }
  }

  async loadListThemes() {
    await this.$http.ressifnet.themesPreventionSanteTravail.paginatedList(1, 100, "", true).then(
      (response: PaginatedList<Theme>) => {
        this.listThemes = response.items
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des themes",
        });
      }
    );
  }

  async deleteProjet() {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le projet ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if (value === true) {
          await this.confirmDelete()
        }
        else {
          return
        }
      })
  }

  async confirmDelete() {
    await this.$http.ressifnet.projetsPreventionSanteTravail.delete(this.$route.params.idEntreprise, this.selectedProjet?.id)
      .then(async (response: string) => {
        successAlert.fire({
          title: "Suppression d\'un projet",
          text: "Suppression effectuée avec succès"
        })
        this.selectedProjet = null
        await this.searchProjets(null)
      }, (error: any) => {
        if (error.response?.data?.detail && error.response.data.detail.length > 0) {
          errorAlert.fire({
            text: error.response.data.detail
          })
        } else {
          errorAlert.fire({
            text: error.message
          })
        }
      })
  }

  
  onAllEntrepriseChange(value: any) {
    if(!value.isTrusted) return;
    this.clearSites();
  }

  clearSites() {
    if(this.selectedProjet) this.selectedProjet.sitesId = [];
    if(this.newProjet) this.newProjet.sitesId = [];
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.listSites = this.listSites?.filter(e => e.id == this.newProjet.sitesId?.some((x: any) => x.id) as any);
      this.totalCountSites = this.listSites.length;
    }

    await this.$http.ressifnet.sites
      .paginatedList(this.$route.params.idEntreprise, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Site>) => {
        this.totalCountSites = res.totalCount;
        this.listSites = this.listSites!.concat(res.items.filter(ri => !this.listSites!.some(lsi => lsi.id == ri.id)));
      })
  }


  reloadList() {
    (this.$refs['listActionsPST'] as any).loadActions()
  }

  selectAction(actionChangeId: any, mode:string) {
    (this.$refs['createActionPST'] as any).selectAction(actionChangeId, mode);
    this.actionId = actionChangeId;
    this.refreshIndex++;
  }

  resetForm() {
    (this.$refs['createActionPST'] as any).resetModel()
    this.actionId = "0"
  }
}
