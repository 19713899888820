

















import { BButton, BSpinner } from 'bootstrap-vue'
import { Component, Vue, Watch } from "vue-property-decorator";
import ListTaches from "./_ListTaches.vue";
import CreateTache from "./_CreateTache.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";

@Component({
  components: {
    ListTaches,
    CreateTache,
    BSpinner,
    BButton,
    SearchableVueSelect
  },
})
export default class AccueilTaches extends Vue {
  tacheId : string = "0";

  reloadList(){
    (this.$refs['listTaches'] as any).loadTaches()
  }

  selectTache(tacheId: string) {
    this.tacheId = tacheId;
    (this.$refs['createTache'] as any).selectTache(tacheId)
  }

  resetForm() {
    (this.$refs['createTache'] as any).resetModel()
    this.tacheId = "0"
  }
}
