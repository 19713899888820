

























































































































































import { BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BFormTextarea,
  BProgress
} from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from '@/api/models/common/paginatedList';
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { Maladie } from '@/api/models/options/maladies/maladie';
import { CreateTacheModel, TypeTache } from '@/api/models/suiviTaches/tache';
import { DroitsEnum } from '@/api/models/enums/droitsEnum';
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar, 
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    BFormTextarea,
    BProgress,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    RessifnetDateInput,
    MultipleFileInput
  },
  name: "create-tache"
})
export default class CreateTache extends Vue {
  loading = false

  maladieSearchQuery = ''
  required = required;
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
  
  newTache: CreateTacheModel = {
    id: undefined,
    entrepriseId: Number(this.$route.params.idEntreprise),
    consigneEcheanceActivee : false,
    fichiers: []
  }
  listTypesTache = new PaginatedList<TypeTache>()

  fichiersUploaded = [];
  percentUpload: number = 0;

  isAddNewTacheSidebarActive = false

  consigneTransmissionsDate : any = '';
  consigneStatus : boolean = false;
  createMode : boolean = true;
  
  droitsEnum = DroitsEnum;

  get canEdit() {
    return !this.newTache.id || this.newTache.createdBy === this.$store.state.user.user.uid || this.$can(this.droitsEnum.ENTREPRISE_MANAG_TOTAL);
  }

  resetModel() {
    this.isAddNewTacheSidebarActive = false
    this.newTache = {
      id: 0,
      entrepriseId: Number(this.$route.params.idEntreprise),
      dateRealisation: this.dateToday,
      fichiers: [],
      consigneEcheanceActivee : false,
      dateConsigneEcheance : undefined
    }
    this.consigneStatus = true;
    this.consigneTransmissionsDate = undefined;
    this.fichiersUploaded = []
  }

  async created() {
    await this.loadListTypesTache({
        pageNumber: 1,
        pageSize: 10,
        actifonly: false
    })   
  }

  async loadListTypesTache(params: any) {
    if (!params || params?.reset) {
        this.listTypesTache = new PaginatedList();
    }

    await this.$http.ressifnet.typesTache.paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10, 
        params.search
      ).then(
      (response: PaginatedList<TypeTache>) => {
        this.listTypesTache.items = this.listTypesTache.items!.concat(response.items.filter(ri => !this.listTypesTache.items!.some(lsi => lsi.id == ri.id)))
        this.listTypesTache.totalCount = response.totalCount
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des types de tâche",
        });
      }
    );
  }

  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de la tache ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.newTache.fichiers = this.newTache
        .fichiers!.filter((f: any) => f.name != fichier.nom );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.taches
      .deleteFile(
        this.$route.params.idEntreprise,
        (this.newTache as any).id,
        fichier.id
      )
      .then((res: any) => {
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        this.fichiersUploaded = this.fichiersUploaded
          .filter((f: any) => f.nom != fichier.nom );

        this.$emit("reload-taches")
      });

  }

  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  async submit(callback: any) {
    let config = {
      onUploadProgress(progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        callback(percentCompleted);
        return percentCompleted;
      },
    };
    if ((this.newTache as any).id && (this.newTache as any).id != undefined) {
      this.updatetache(config);
    } else {
      this.createTache(config);
    }
  }
  
  async updatetache(config: any) {
    this.newTache.dateConsigneEcheance = this.consigneTransmissionsDate ? this.consigneTransmissionsDate : undefined;
    this.newTache.consigneEcheanceActivee = !this.consigneStatus;
    this.newTache.dateConsigneEcheance = !this.newTache.consigneEcheanceActivee ? undefined : this.newTache.dateConsigneEcheance;
    this.loading = true;
    await this.$http.ressifnet.taches.put(this.$route.params.idEntreprise, this.newTache.id, this.newTache, config)
      .then(
        async (response: any) => {
            successAlert.fire({
              timer: 3500,
              title: "Modification d'une tâche",
              text: "Tâche modifié avec succès",
            });
            this.isAddNewTacheSidebarActive = false
            this.percentUpload = 0
            this.$emit("reload-taches")
          },     
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.percentUpload = 0
        }
      )
      .finally(() => {
        this.loading = false
      });
  }

  async createTache(config: any) {

    this.loading = true;

    // Checkbox boolean inversé ( si checked alors true)
    this.newTache.consigneEcheanceActivee = !this.consigneStatus;

    // Si la date est differente de la date par defaut de rappel
    if(this.consigneTransmissionsDate != this.getConsigneTransmissionDateByTacheId(this.newTache.typeTacheId)){
      this.newTache.dateConsigneEcheance = this.consigneTransmissionsDate;
    }

    await this.$http.ressifnet.taches.post(this.$route.params.idEntreprise, this.newTache, config)
      .then(
        async (response: any) => {
            successAlert.fire({
              timer: 3500,
              title: "Création d'une tâche",
              text: "Tâche créée avec succès",
            });
            this.isAddNewTacheSidebarActive = false
            this.percentUpload = 0
            this.$emit("reload-taches")
          },     
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.percentUpload = 0
        }
      )
      .finally(() => {
        this.loading = false
      });
  }

  async selectTache(id: string) {
    if (id == null || id == "0") {
      this.resetModel();      
      this.createMode = true;
      this.consigneStatus = false;
      this.consigneTransmissionsDate = '';
      this.isAddNewTacheSidebarActive = true;
    }
    else {
      this.createMode = false;
      await this.$http.ressifnet.taches
        .getById(this.$route.params.idEntreprise, id)
        .then((tache: any) => {
          this.newTache = {
            id: Number(tache.id),
            entrepriseId: Number(this.$route.params.idEntreprise),
            dateRealisation: tache.dateRealisation,
            typeTacheId: tache.typeTacheId,
            fichiers: [],
            createdBy: tache.createdBy,
            consigneEcheanceActivee: tache.consigneEcheanceActivee,
            dateConsigneEcheance : tache.dateConsigneEcheance
          };
          this.loading = false;

          this.consigneStatus = !tache.consigneEcheanceActivee;

          this.consigneTransmissionsDate = this.newTache.dateConsigneEcheance ? this.newTache.dateConsigneEcheance : this.getConsigneTransmissionDateByTacheId(this.newTache.typeTacheId);

          this.fichiersUploaded = tache.fichiers
        });
      this.isAddNewTacheSidebarActive = true
    }
  }

  get fichiers() {
    var files = [];

    if (this.newTache.fichiers!.length > 0) {
      files = this.newTache
        .fichiers!.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded ?? []);
  }

  getFormatedConsigneDate(intervale : number){
    var dateString = this.newTache.dateRealisation!;
    var parts = dateString.split('/');
    var formattedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];
    var date = new Date(formattedDateString);
    date.setMonth(date.getMonth() - intervale);
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var jourStr = jour < 10 ? '0' + jour : jour.toString();
    var moisStr = mois < 10 ? '0' + mois : mois.toString();
    var anneeStr = annee.toString();
    var dateResultat = jourStr + '/' + moisStr + '/' + anneeStr;
    return dateResultat;
  }

  getConsigneTransmissionDateByTacheId(typeTacheId: any){
    var typeTacheLibelle = this.listTypesTache.items.find(x => x.id == typeTacheId) ? this.listTypesTache.items.find(x => x.id == typeTacheId)!.libelle : '';

    var dateTransmissionsByTypeTache : any = '';

    switch (typeTacheLibelle) {
      case ('Etalonnage'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-2)
        break;
      case ('Péremption'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-1)
        break;
      case ('Traçabilité'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-1)      
        break;
      case ('Protocoles'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-10)      
        break;
      case ('Outils de suivi IST'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-12)      
        break;
      default:
      dateTransmissionsByTypeTache = '';
        break;
    }
    return dateTransmissionsByTypeTache;
  }

  getConsigneTransmissionsDate(typeTacheId :any){
    this.consigneTransmissionsDate = this.getConsigneTransmissionDateByTacheId(typeTacheId);
  }
}
