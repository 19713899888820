












































































































































































































import { ConsigneCreateModel } from "@/api/models/dossiers/consignes";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Equipe } from "@/api/models/options/equipes/equipe";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BFormCheckbox,
  BSpinner,
  BFormTextarea,
  BFormSelect,
  VBTooltip
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";

@Component({
  components: {
    RessifnetDateInput,
    RessifnetQuillEditor,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    SearchableVueSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives :{
    'b-tooltip': VBTooltip,
  }
})
export default class CreationConsignesEntreprise extends Vue {
  required = required;
  refreshIndex = 1;

  dateToday = new Date().toLocaleString("fr-FR", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  loading = true;

  displayNouvelleDemande: boolean = false;

  selectedIds: any = {};
  model: ConsigneCreateModel = {
    dateDebut: this.dateToday,
    dateEcheance: this.dateToday,
    importance: undefined,
    message: undefined,
    destinatairesEquipes: [],
    destinatairesUtilisateurs: [],
  };

  equipeSelected: boolean = false;
  redacteurSelected: boolean = true;
  importanceWasSelected: boolean = false;

  listEquipes: Equipe[] = [];
  listEquipesTotalCount = 0;
  listMembres: UserDetail[] = [];
  listMembresTotalCount = 0;

  async created() {
    await Promise.all([]).then(() => (this.loading = false));
  }

  resetModel() {
    this.importanceWasSelected = false;
    this.model = {
      dateEcheance: this.dateToday,
      dateDebut: this.dateToday,
      importance: undefined,
      message: undefined,
      destinatairesUtilisateurs: [],
      destinatairesEquipes: [],
    };
    this.selectedIds = {};
    this.redacteurSelected = false;
    this.equipeSelected = false;

    this.resetIconsToDefault();

    (this.$refs['formValidation'] as any).reset();

  }

  resetIconsToDefault() {
    var icons = document.querySelectorAll(".icon-status-item");
    icons.forEach((icon) => {
      icon.classList.remove("active");
    });
  }

  async save() {
    let data: ConsigneCreateModel = {
      message: this.model.message,
      entrepriseId: this.$route.params.idEntreprise,
      dateDebut: this.model.dateDebut,
      dateEcheance: this.model.dateEcheance,
      importance: this.model.importance,
      nouvelleDemande: this.model.nouvelleDemande ?? false,
      destinatairesEquipes: this.model.destinatairesEquipes,
      destinatairesUtilisateurs: this.model.destinatairesUtilisateurs,
      redacteurDestinataire: this.redacteurSelected ?? false,
    };
    await this.$http.ressifnet.consignes
      .createForEntreprise(this.$route.params.idEntreprise, data)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Création d'une consigne",
            text: "Consigne ajoutée avec succès",
          });

          this.resetModel()
          this.$emit("loadListConsignes")
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        },
      );
  }

  async searchEquipes(params?: any) {
    if (!params || params?.reset) {
      this.listEquipes =
        this.listEquipes!.filter((e: any) =>
          this.model.destinatairesEquipes?.includes(e.id),
        ) ?? [];
    }

    await this.$http.ressifnet.consignes
      .paginatedListEquipes(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then(
        (response: PaginatedList<Equipe>) => {
          this.listEquipes = this.listEquipes!.concat(
            response.items.filter(
              (ri) => !this.listEquipes!.some((lsi: any) => lsi.id == ri.id),
            ),
          );
          this.listEquipesTotalCount = response.totalCount;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        },
      );
  }

  async searchMembres(params?: any) {
    if (!params || params?.reset) {
      this.listMembres =
        this.listMembres!.filter((e: any) =>
          this.model.destinatairesUtilisateurs?.includes(e.id),
        ) ?? [];
    }

    await this.$http.ressifnet.consignes
      .paginatedListMembres(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then(
        (response: PaginatedList<UserDetail>) => {
          this.listMembres = this.listMembres!.concat(
            response.items.filter(
              (ri) => !this.listMembres!.some((lsi: any) => lsi.id == ri.id),
            ),
          );
          this.listMembresTotalCount = response.totalCount;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        },
      );
  }

  async loadEquipes() {
    if (this.equipeSelected) {
      this.model.destinatairesEquipes = undefined;
      await this.searchEquipes(null);
    } else {
      this.model.destinatairesEquipes = undefined;
      this.listEquipes = [];
    }
  }

  async loadMembres() {
    if (this.selectedIds) {
      this.model.destinatairesUtilisateurs = undefined;
      await this.searchMembres(null);
    } else {
      this.model.destinatairesUtilisateurs = undefined;
      this.listMembres = [];
    }
  }

  onSelectedImportance(value: any, element: any, model: any) {
    // Element avec déjà une valeur -> ToSave
    if (
      element.target.closest(".icon-status-item").classList.contains("active")
    ) {
      this.importanceWasSelected = true;
      element.target.closest(".icon-status-item").classList.remove("active");
      model.importance = null;
    }
    // Element sans valeur
    else {
      var parent = element.target.closest(".icon-list-custom");
      var icons = parent.querySelectorAll(".icon-status-item");
      icons.forEach((icon: any) => {
        icon.classList.remove("active");
      });
      element.target.closest(".icon-status-item").classList.toggle("active");

      model.importance = value;
    }
    this.refreshIndex++;
  }
}
