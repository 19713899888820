














































































































import { Vue, Component } from "vue-property-decorator"
import AccueilPreventionSanteTravail from "./prevention-sante-travail/Index.vue";
import AccueilPreventionSantePublique from "./prevention-sante-publique/Index.vue";
import AccueilAdministratif from "./administratif/Index.vue";
import AccueilMilieuTravail from "./milieuTravail/Index.vue";
import AccueilTaches from "./suivisTache/Index.vue";
import { BTabs, BTab, BCardText, BButton } from 'bootstrap-vue';
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import Fichiers from './fichiers/_Fichiers.vue';
import ListeConsignesEntreprise from "./consignes/_ListeConsignes.vue";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";

@Component({
  components: {
    AccueilPreventionSanteTravail,
    AccueilAdministratif,
    AccueilPreventionSantePublique,
    AccueilMilieuTravail,
    ListeConsignesEntreprise,
    Fichiers,
    BTabs,
    BTab,
    BCardText,
    AccueilTaches,
    BButton
  }
})
export default class NavigationTabs extends Vue {
  droitsEnum = DroitsEnum;
  
  // créer un type
  entreprise: any = null;
  clearModel = {};
  tabs: string[] = [];
  tabIndex: number = 0;
  diffCount: number = 0;
  diff: any;
  diffMode = "";
  prevTab: number = 0;
  nextTab: number = 0;


  async created() {
    await this.loadEntreprise();
  }

  deepDiff(obj1: any, obj2: any) {
    const diff: any = {};

    for (const key in obj1) {
      if (key === "vue") {
        continue;
      }


          const value1 = obj1[key];
          const value2 = obj2[key];

          if (value1 !== value2) {
            if (
              (value1 === null && value2 === undefined) ||
              (value1 === undefined && value2 === null)
            ) {
              continue;
            }

            if (typeof value1 === "object" && typeof value2 === "object") {
              if (Array.isArray(value1) && Array.isArray(value2)) {
                if (JSON.stringify(value1) !== JSON.stringify(value2)) {
                  diff[key] = { obj1: value1, obj2: value2 };
                }
              } else {
                const nestedDiff = this.deepDiff(value1, value2);
                if (Object.keys(nestedDiff).length > 0) {
                  diff[key] = nestedDiff;
                }
              }
            } else {
              diff[key] = { obj1: value1, obj2: value2 };
            }
          }
    }

    return diff;
  }

  leaveWithoutSaving() {
    let tabAdministratifRef = this.$refs["tabAdministratif"] as any;    
    let tabActionMilieuTravailRef = this.$refs["tabActionMilieuTravail"] as any;    
    
   if (
      tabAdministratifRef &&
      tabAdministratifRef.$refs["createAction"]
    ) {
      let form = tabAdministratifRef.$refs["createAction"];
      if (form) {
        tabAdministratifRef.$refs["createAction"].resetModel();
        tabAdministratifRef.$refs["createAction"].resetClearModel();
      }
    }

     if (
      tabActionMilieuTravailRef &&
      tabActionMilieuTravailRef.$refs["createAction"]
    ) {
      let form = tabActionMilieuTravailRef.$refs["createAction"];
      if (form) {
        tabActionMilieuTravailRef.$refs["createAction"].resetModel();
      }
    }
  
  
    this.$bvModal.hide("modificationsModel");
    this.tabIndex = this.nextTab;
  }

  reloadClearModel() {
    let situationProRef = this.$refs["tabsSituationProfessionelle"] as any;
    let tabAdministratifRef = this.$refs["tabsInformationSante"] as any;
    let etudeDePosteRef = this.$refs["tabsEtudeDePoste"] as any;

    if (etudeDePosteRef) {
      etudeDePosteRef.createClone();
    }

    if (situationProRef) {
      situationProRef.createClone();
    }

    if (tabAdministratifRef) {
      tabAdministratifRef.createClone();
    }
    this.createClone();
  }

  async saveModifications() {
    var currentIndex = (this.$refs["dossierTabs"] as any).value;
    var tabs = (this.$refs["dossierTabs"] as any).getTabs();

    await tabs[currentIndex].$children[0].silentUpdate().then(() => {
      this.reloadClearModel();
      this.$bvModal.hide("modificationsModel");
      this.tabIndex = this.nextTab;
    });
  }

  async tabsChanged(nextTab: number, previousTab: number, event: any) {
    this.nextTab = nextTab;
    this.prevTab = previousTab;

    let tabActionMilieuTravailRef = this.$refs["tabActionMilieuTravail"] as any;    
    let tabAdministratifRef = this.$refs["tabAdministratif"] as any;    
    let tabPreventionSanteTravailRef = this.$refs["tabPreventionSanteTravail"] as any;    

    var globalDiff: any = {};
    var administratifDiff: any = {};
    var milieuTravailDiff: any = {};
    var tabPreventionSanteTravailDiff: any = {};

    if (tabActionMilieuTravailRef) {           
      if(tabActionMilieuTravailRef.$refs['createAction']){
        let clearModel = tabActionMilieuTravailRef.$refs['createAction'].clearModel;
        let model = tabActionMilieuTravailRef.$refs['createAction'].model;
        milieuTravailDiff = this.deepDiff(model,clearModel );
      }     
    }

    if (tabAdministratifRef) {      
      if(tabAdministratifRef.$refs['createAction']){
        var clearModel = tabAdministratifRef.$refs['createAction'].clearModel;
        var model = tabAdministratifRef.$refs['createAction'].model; 
        administratifDiff = this.deepDiff(model,clearModel);
      }     
    }

    if (tabPreventionSanteTravailRef) {      
      let form = tabPreventionSanteTravailRef.$refs['createAction'];
      if(form){
        let clearModel = form.clearModel;
        let model = form.newProjet;
        tabPreventionSanteTravailDiff = this.deepDiff(clearModel, model);
      }     
    }

    if (Object.keys(tabPreventionSanteTravailDiff).length > 0) {
      globalDiff = { ...tabPreventionSanteTravailDiff };
      this.diffMode = "pst";
    }

    if (Object.keys(administratifDiff).length > 0) {
      globalDiff = { ...administratifDiff };
      this.diffMode = "administratif";
    }

    if (Object.keys(milieuTravailDiff).length > 0) {
      globalDiff = { ...milieuTravailDiff };
      this.diffMode = "milieuTravail";
    }
    
    this.diff = globalDiff;

    if (Object.keys(globalDiff).length > 0) {
      event.preventDefault();
      this.diffCount = Object.keys(globalDiff).length;
      this.$bvModal.show("modificationsModel");
    }
  }

  createClone() {
    var fakeClone = JSON.parse(JSON.stringify(this.entreprise));
    this.clearModel = fakeClone;
  }

  async loadEntreprise() {
    await this.$http.ressifnet.entreprises.getById(this.$route.params.idEntreprise, true)
      .then(
        (response: Entreprise) => {
          this.entreprise = response
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des informations de l\'entreprise'
          })
        }
      )
  }
} 
