

















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { BPagination, BSpinner, VBPopover, BButton, BFormCheckbox } from 'bootstrap-vue';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import { CreateTacheModel, Tache } from "@/api/models/suiviTaches/tache"
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import CreateTache from './_CreateTache.vue';

@Component({
  name: 'list-taches-milieu-travail',
  components: {
    BPagination,
    BSpinner,
    BButton,
    DeleteIcon,
    EditIcon,
    RessifnetDateInput,
    BFormCheckbox
  },
  directives: {
    'b-popover': VBPopover
  }
})
export default class ListTaches extends Vue {
  droitsEnum = DroitsEnum;

  currentPage = 1;
  perPage = 5;

  paginatedList : PaginatedList<Tache> = new PaginatedList<Tache>()

  listLoading = true

  consigneTransmissionsDate : any = '';
  tacheSelected : CreateTacheModel = {};
  consigneStatus : boolean = false;

  async created() {
    await this.loadTaches();
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadTaches()
  }

  async loadTaches(siteId?: string) {
    this.listLoading = true;
    
    await this.$http.ressifnet.taches
      .paginatedList(this.$route.params.idEntreprise, this.currentPage, this.perPage)
      .then(    
        (res: PaginatedList<Tache>) => {
          this.paginatedList = res;
          this.listLoading = false
        })
  }

  async deleteTache(id: string) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la tâche ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.confirmDeleteTache(id)
          } 
          else {
            return
          }
        })
  }

  async confirmDeleteTache(id: string) {
    await this.$http.ressifnet.taches
      .delete(this.$route.params.idEntreprise, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une tache",
            text: "Suppression effectuée avec succès",
          });
          this.loadTaches()
          this.$emit('reset-form')
        }
      )
  }

  async downloadFile(tache: any) {
    if (tache.fichiers.length == 0)
      return;
    else {
      tache.fichiers.forEach(async (x: any) => {
        await this.$http.ressifnet.fichiersEntreprises
        .downloadFile(x.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
      });
    }
  }

  getFormatedConsigneDate(intervale : number){
    var dateString = this.tacheSelected.dateRealisation!;
    var parts = dateString.split('/');
    var formattedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];
    var date = new Date(formattedDateString);
    date.setMonth(date.getMonth() - intervale);
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var jourStr = jour < 10 ? '0' + jour : jour.toString();
    var moisStr = mois < 10 ? '0' + mois : mois.toString();
    var anneeStr = annee.toString();
    var dateResultat = jourStr + '/' + moisStr + '/' + anneeStr;
    return dateResultat;
  }

  getConsigneTransmissionDateByTacheLibelle(typeTacheLibelle: string){
  
    var dateTransmissionsByTypeTache : any = '';

    switch (typeTacheLibelle) {
      case ('Etalonnage'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-2)
        break;
      case ('Péremption'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-1)
        break;
      case ('Traçabilité'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-1)      
        break;
      case ('Protocoles'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-10)      
        break;
      case ('Outils de suivi IST'):
      dateTransmissionsByTypeTache = this.getFormatedConsigneDate(-12)      
        break;
      default:
      dateTransmissionsByTypeTache = '';
        break;
    }
    return dateTransmissionsByTypeTache;
  }

  async selectTache(id: string) {
    this.$emit('select-tache', id)
  }

  toggleModal(tache:any){
    this.tacheSelected = tache;
    this.consigneStatus = !tache.consigneEcheanceActivee;
    this.consigneTransmissionsDate = this.tacheSelected.dateConsigneEcheance ? this.tacheSelected.dateConsigneEcheance : this.getConsigneTransmissionDateByTacheLibelle(tache.typeTacheLibelle);
    this.$bvModal.show('modal-consignes');
  }

  async updatetache() {
    
    this.tacheSelected.consigneEcheanceActivee = !this.consigneStatus;

    if(this.consigneTransmissionsDate !=  this.getConsigneTransmissionDateByTacheLibelle(this.tacheSelected.typeTacheLibelle!)){
      this.tacheSelected.dateConsigneEcheance = this.consigneTransmissionsDate;
    }

    this.tacheSelected
    await this.$http.ressifnet.taches.put(this.$route.params.idEntreprise, this.tacheSelected.id, this.tacheSelected)
      .then(
        async (response: any) => {
            successAlert.fire({
              timer: 3500,
              title: "Modification d'une tâche",
              text: "Tâche modifié avec succès",
            });
            this.loadTaches();
          },     
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )      
  }

}
