


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  BProgress,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
  BSpinner,
  BModal,
  BButton,
  BTab,
  BCardText,
  BTabs,
  BFormCheckboxGroup,
  VBTooltip
} from "bootstrap-vue";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Site } from "@/api/models/options/entreprises/site";
import { Region } from "@/api/models/options/entreprises/region";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { required } from "@validations";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import {
  CreateActionMilieuTravailModel,
  CreateActionMilieuTravailClassiqueModel,
  CreateActionMilieuTravailEtudePosteModel,
} from "@/api/models/actionsMilieuTravail/actionMilieuTravail";
import { TypeActionMilieuTravail } from "@/api/models/actionsMilieuTravail/typeActionMilieuTravail";
import { PartiePrenanteMilieuTravail } from "@/api/models/actionsMilieuTravail/partiePrenanteMilieuTravail";
import { TypeCodeActionMilieuTravail } from "../../../api/models/actionsMilieuTravail/typeActionMilieuTravail";
import { FamilleDeRisqueEntreprise } from "@/api/models/options/entreprises/actionMilieuTravail/famillesDeRisqueEntreprise";
import { BinomeEntreprise } from "@/api/models/options/binomesEntreprise/binomeEntreprise";
import { PrescripteurEntreprise } from "@/api/models/options/prescripteursEntreprise/prescripteurEntreprise";
import { ContexteEntreprise } from "@/api/models/options/contextesEntreprise/contexteEntreprise";
import { TypeDePosteEntreprise } from "@/api/models/options/typesDePosteEntreprise/typeDePosteEntreprise";
import { Tag } from "@/api/models/options/entreprises/tag";
import {
  Dossier,
  DossierSearchParams,
} from "../../../api/models/dossiers/dossier";
import {
  ImpactEntreprise,
  ImpactEntrepriseCreateModel,
} from "@/api/models/options/entreprises/actionMilieuTravail/impactsEntreprise";
import Cleave from "vue-cleave-component";
import VueSlider from "vue-slider-component";
import {
  RisqueEntreprise,
  RisqueEntrepriseCreateModel,
  RisqueEntrepriseToSave,
} from "@/api/models/options/entreprises/actionMilieuTravail/risquesEntreprise";
import { ValeurMesureEntreprise } from "@/api/models/options/entreprises/actionMilieuTravail/valeursMesuresEntreprise";
import { MesureEntrepriseCreateModel } from "@/api/models/options/entreprises/actionMilieuTravail/mesuresEntreprise";
import { NiveauAlerteEntrepriseCreateModel } from "@/api/models/options/entreprises/actionMilieuTravail/niveauxAlerteEntreprise";
import {
  SousImpactEntreprise,
  SousImpactEntrepriseCreateModel,
} from "@/api/models/options/entreprises/actionMilieuTravail/sousImpactsEntreprise";
import { NiveauAlerteValeur } from "@/api/models/enums/niveauAlerteValeur";
import { NiveauAlerteCreateModel } from "@/api/models/dossiers/niveauxAlerte";
import { ActionMilieuDetravail } from "@/api/models/entreprise/actionMilieuDeTravail";
import { TYPE } from "vue-toastification";
import { SecteurActivite } from "@/api/models/options/secteursActivites/secteurActivite";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  name: "create-action-milieu-travail",
  components: {
    BProgress,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    RessifnetDateInput,
    RessifnetQuillEditor,
    BSpinner,
    BForm,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
    BModal,
    BTab,
    BTabs,
    BCardText,
    VueSlider,
    Cleave,
    BFormCheckboxGroup,
    MultipleFileInput
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
})
export default class CreateActionMilieuTravail extends Vue {
  @Prop({ required: true }) secteurActiviteId!: string;

  // required = required;
  typeCodeActionMilieuTravail = TypeCodeActionMilieuTravail;
  loading = false;
  nonEvalueStress: boolean = false;
  niveauAlertEmpty: boolean = false;
  ticksLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  dateToday = new Date().toLocaleString("fr-FR", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  typeCodeSelected: string = TypeCodeActionMilieuTravail.ACTION_CLASSIQUE;
  refreshIndex = 0;
  tempCodeSelected: string = "";
  formTitle: string = "Créer une nouvelle action";
  formIcon: string = "Plus";
  viewMode: boolean = false;
  editMode: boolean = false;
  createMode: boolean = false;
  canSelectSecteurs: boolean = false;

  typeRequiringDureeVacation = false;
  typeRequiringImpact = false;
  typeRequiringLabel = false;
  loadImpactsList = false;
  hasSousImpact = false;
  allEntreprise = false;
  loadEntreprisesParts = false;
  listTypes: PaginatedList<TypeActionMilieuTravail> =
    new PaginatedList<TypeActionMilieuTravail>();
  listPartiesPrenantes: PaginatedList<PartiePrenanteMilieuTravail> =
    new PaginatedList<PartiePrenanteMilieuTravail>();
  famillesDeRisques: PaginatedList<FamilleDeRisqueEntreprise> =
    new PaginatedList<FamilleDeRisqueEntreprise>();
  prescripteurs: PaginatedList<PrescripteurEntreprise> =
    new PaginatedList<PrescripteurEntreprise>();
  typesDePoste: PaginatedList<TypeDePosteEntreprise> =
    new PaginatedList<TypeDePosteEntreprise>();
  binomes: PaginatedList<BinomeEntreprise> =
    new PaginatedList<BinomeEntreprise>();
  contextes: PaginatedList<ContexteEntreprise> =
    new PaginatedList<ContexteEntreprise>();
    
 secteursActivite: PaginatedList<SecteurActivite> =
    new PaginatedList<SecteurActivite>();

  model: CreateActionMilieuTravailModel = {
    actionClassique: {
      duree: undefined,
      dureeHorsVacation: undefined,
      libelle: undefined,
      nbEmployes: undefined,
      partiesPrenantesId: undefined,
      branchesId: [],
      sitesId: [],
      regionsId: [],
      secteursId: [],
      partiesPrenantesExternesId: [],
      partiesPrenantesInternesId: [],
      typeRequiringImpact: false,
      typeRequiringLabel: false,
    },
    etudeDePoste: {
      binomesId: [],
      contextesId: [],
      branchesId: [],
      regionsId: [],
      risques: [],
      secteursId: [],
      sitesId: [],
      tagsId: undefined,
      dossiersId: undefined,
      stress: 1,
      typeDePosteId: undefined,
    },
    fichiers: [],
    dateAction: this.dateToday,
    commentaire: undefined,
  };
  clearModel : CreateActionMilieuTravailModel = {
    actionClassique: {
      duree: undefined,
      dureeHorsVacation: undefined,
      libelle: undefined,
      nbEmployes: undefined,
      partiesPrenantesId: undefined,
      branchesId: [],
      sitesId: [],
      regionsId: [],
      secteursId: [],
      partiesPrenantesExternesId: [],
      partiesPrenantesInternesId: [],
      typeRequiringImpact: false,
      typeRequiringLabel: false,
    },
    etudeDePoste: {
      binomesId: [],
      contextesId: [],
      branchesId: [],
      regionsId: [],
      risques: [],
      secteursId: [],
      sitesId: [],
      tagsId: undefined,
      dossiersId: undefined,
      stress: 1,
      typeDePosteId: undefined,
    },
    fichiers: [],
    dateAction: this.dateToday,
    commentaire: undefined
  };
  modelClear = {...this.model};

  initialModel: any

  fichiersUploaded = [];

  percentUpload: number = 0;

  errorList: any = {};

  branches?: Branche[] = [];
  totalCountBranches: number = 0;

  sites?: Site[] = [];
  totalCountSites: number = 0;

  regions?: Region[] = [];
  totalCountRegions: number = 0;

  secteurs?: Secteur[] = [];
  totalCountSecteurs: number = 0;

  tags?: Tag[] = [];
  totalCountTags: number = 0;

  dossiers?: Dossier[] = [];
  totalCountDossiers: number = 0;
  totalCountDossiersManuel: number = 0;

  listeTags: Tag[] = [];
  listDossiersManuel: Dossier[] = [];
  listBranches: Branche[] = [];
  listSites: Site[] = [];
  listRegions: Region[] = [];
  listSecteurs: Secteur[] = [];

  // FILTRES SITES
  sitesManuel: Site[] = [];
  listSitesManuel: Site[] = [];
  totalCountSitesManuel: number = 0;

  // FILTRES TAGS
  listeTagsManuel: Tag[] = [];
  tagsManuel: Tag[] = [];
  totalCountTagsManuel: number = 0;

  searchDossiersManuelParams: DossierSearchParams = {
    entrepriseId: this.$route.params.idEntreprise,
    sitesId: [],
    tagsId: [],
  };

  searchDossierParams: DossierSearchParams = {
    entrepriseId: this.$route.params.idEntreprise,
    sitesId: [],
    secteursId: [],
    branchesId: [],
    regionsId: [],
    tagsId: [],
  };

  lastTypeCode: string = TypeCodeActionMilieuTravail.ACTION_CLASSIQUE;
  droitsEnum = DroitsEnum;
  
  get canEdit() {
    return !this.model.id || this.model.createdBy === this.$store.state.user.user.uid || this.$can(this.droitsEnum.ENTREPRISE_MANAG_TOTAL);
  }

  get typeAction() {
    return this.model.typeId
      ? this.listTypes.items.find((x) => x.id == this.model.typeId)?.code
      : "";
  }

  async created() {
    this.initialModel = JSON.parse(JSON.stringify(this.clearModel));
    await this.loadTypesAction();
    await this.loadPartiesPrenantes();
    await this.loadFamilleDeRisques();
    await this.searchTypesDePoste(null);
    await this.searchBinomes(null);
    await this.searchContextes(null);
    await this.searchPrescripteurs(null);
    await this.countDossiers(null);
  }

  @Watch("sites")
  @Watch("tags")
  @Watch("secteurs")
  @Watch("branches")
  @Watch("regions")
  onChangeSelection() {
    this.updateSearchDossierParams();
    if (this.typeCodeSelected == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
      if(this.sites?.length != 0 || this.regions?.length != 0 || this.tags?.length != 0 || this.secteurs?.length != 0 || this.branches?.length != 0)
      this.countDossiers(null);
    }
  }

  @Watch('model')
  modelUpdated() {
    (this.$refs["formValidation"] as any)
      .validate()
      .then((result: any) => { console.log('res', result)})
  }

  @Watch("sitesManuel")
  @Watch("tagsManuel")
  onChangeSelectionManuel() {
    this.updateSearchDossiersManuelParams();
    this.searchDossiers(null);
  }

  resetSearchParams() {
    this.dossiers = [];
    this.tags = [];
    this.regions = [];
    this.sites = [];
    this.secteurs = [];
    this.branches = [];

    this.searchDossiersManuelParams = {
      entrepriseId: this.$route.params.idEntreprise,
      sitesId: [],
      tagsId: [],
    };

    this.searchDossierParams = {
      entrepriseId: this.$route.params.idEntreprise,
      sitesId: [],
      secteursId: [],
      branchesId: [],
      regionsId: [],
      tagsId: [],
    };

    (this.model.actionClassique = {
      duree: undefined,
      dureeHorsVacation: undefined,
      libelle: undefined,
      nbEmployes: undefined,
      partiesPrenantesId: undefined,
      branchesId: [],
      sitesId: [],
      regionsId: [],
      secteursId: [],
      tagsId: undefined,
      partiesPrenantesExternesId: [],
      partiesPrenantesInternesId: [],
      typeRequiringImpact: false,
      typeRequiringLabel: false,
    }),
      (this.model.etudeDePoste = {
        binomesId: [],
        contextesId: [],
        branchesId: [],
        regionsId: [],
        risques: [],
        secteursId: [],
        sitesId: [],
        tagsId: undefined,
        dossiersId: undefined,
        stress: 1,
        typeDePosteId: undefined,
      });
  }

  updateSearchDossierParams() {
    this.searchDossierParams.sitesId = this.sites?.map((site) => site.id) || [];
    this.searchDossierParams.tagsId = this.tags?.map((tag) => tag.id) || [];
    this.searchDossierParams.secteursId =
      this.secteurs?.map((secteur) => secteur.id) || [];
    this.searchDossierParams.branchesId =
      this.branches?.map((branche) => branche.id) || [];
    this.searchDossierParams.regionsId =
      this.regions?.map((region) => region.id) || [];
  }

  updateSearchDossiersManuelParams() {
    this.searchDossiersManuelParams.sitesId =
      this.sitesManuel?.map((site) => site.id) || [];
    this.searchDossiersManuelParams.tagsId =
      this.tagsManuel?.map((tag) => tag.id) || [];
  }

  clearStress(val: any) {
    if (val) {
      if (this.model.etudeDePoste) {
        this.model.etudeDePoste.stress = 0;
        (this.$refs["slider"] as any).setIndex(0);
      }
      this.nonEvalueStress = val;
    }
  }

  selectRisque(value: any, risque: RisqueEntreprise, famille: string) {
    risque.familleLibelle = famille;
        this.model.etudeDePoste!.risques?.push(risque);
        this.model.etudeDePoste!.risques = this.model.etudeDePoste!.risques!.filter((risque) =>
         value.includes(risque.id)
        );
    this.refreshIndex++;
  }

  unselectRisque(risque: any) {
    if (this.model.etudeDePoste) {
      this.model.etudeDePoste.risques = this.model.etudeDePoste.risques!.filter((x:any) => x.id != risque.id);
      this.model.etudeDePoste.risquesIds = this.model.etudeDePoste.risquesIds!.filter((x:any) => x != risque.id);
    }
    this.refreshIndex++;
  }

  toggleNonEvaluate() {
    this.nonEvalueStress = false;
    this.refreshIndex++;
  }

  addMesureValue(
    value: any,
    mesure: MesureEntrepriseCreateModel,
    risque: RisqueEntrepriseCreateModel
  ) {
    if (value) {
      mesure.selected = true;
      mesure.valeur = null;
      var valeurMesure: ValeurMesureEntreprise = {
        valeur: mesure.valeur,
        mesure: mesure,
      };
      if (!risque.valeursMesures) {
        risque.valeursMesures = [];
        risque.valeursMesures.push(valeurMesure);
      } else {
        risque.valeursMesures.push(valeurMesure);
      }
    } else {
      mesure.selected = false;
      mesure.valeur = null;
      if (risque.valeursMesures) {
        var index = risque.valeursMesures.indexOf(
          risque.valeursMesures.filter((x) => x.mesure == mesure)[0]
        );
        if (index !== -1) {
          risque.valeursMesures.splice(index, 1);
        }
      }
    }
    this.refreshIndex++;
  }

  getOptionsImpact(risque: RisqueEntrepriseCreateModel) {
    if (
      risque.impactsLibelle &&
      risque.impactsLibelle.length != 0 &&
      Object.values(risque.impactsLibelle)
    ) {
      return Object.values(risque.impactsLibelle);
    }
  }

  onSousImpactUnselect(sousImpact: any, risque: RisqueEntrepriseCreateModel) {
    var sousImpactToSaveIndex = risque.sousImpactsToSave.indexOf(sousImpact);
    if (sousImpactToSaveIndex !== -1) {
      risque.sousImpactsToSave.splice(sousImpactToSaveIndex, 1);
    }

    if (risque.niveauxAlertesList) {
      var ele: any = risque.niveauxAlertesList.find(
        (element: any) => element.sousImpactLibelle == sousImpact.libelle
      );
      var indexOfUnSelect = risque.niveauxAlertesList.indexOf(ele);
      if (indexOfUnSelect !== -1) {
        risque.niveauxAlertesList.splice(indexOfUnSelect, 1);
      }
    }

    this.refreshIndex++;
  }

  removeNiveauAlert(
    niveauAlert: NiveauAlerteEntrepriseCreateModel,
    risque: RisqueEntrepriseCreateModel
  ) {

    if(this.viewMode){
      return;
    }

    // Pas de sousImpact et impacts
    if(risque.impacts && !niveauAlert.sousImpactId){
      risque.impacts = risque.impacts.filter((x:ImpactEntrepriseCreateModel) => x.id != niveauAlert.impactId)
      this.refreshIndex++;
    }

    type listSousImpacts = {sousImpactsLibelle: { id: string }[]};

     // SousImpacts present 
    if(risque.impacts && niveauAlert && niveauAlert.sousImpactId && risque.impacts.some((x:listSousImpacts) => x.sousImpactsLibelle && x.sousImpactsLibelle.length != 0) ){
        risque.impacts = risque.impacts.filter((x: listSousImpacts) =>
        x.sousImpactsLibelle = x.sousImpactsLibelle.filter((y: { id: string }) => y.id !== niveauAlert.sousImpactId)
        );
    }

    if (risque.sousImpactsToSave) {
      risque.sousImpactsToSave = risque.sousImpactsToSave.filter(
        (x) => x.id != niveauAlert.sousImpactId
      );
    }

    risque.niveauxAlertesList = risque.niveauxAlertesList.filter((x:any) => x.impactId != niveauAlert.impactId)

    this.refreshIndex++;
    }
  

  deleteNiveauAlerte(
    sousImpact: SousImpactEntrepriseCreateModel,
    risque: RisqueEntrepriseCreateModel
  ) {
    var index = risque.niveauxAlertesList.find(
      (e) => e.sousImpactId == sousImpact.id
    ) as any;
    if (index !== -1) {
      risque.niveauxAlertesList.splice(index, 1);
    }
    this.refreshIndex++;
  }

  deleteSousImpact(
    sousImpact: SousImpactEntrepriseCreateModel,
    risque: RisqueEntrepriseCreateModel
  ) {
    var index = risque.sousImpactsToSave.find(
      (e) => e.libelle == sousImpact.libelle
    ) as any;
    if (index !== -1) {
      risque.sousImpactsToSave.splice(index, 1);
    }
    this.refreshIndex++;
  }

  onImpactUnselect(
    impact: ImpactEntrepriseCreateModel,
    risque: RisqueEntrepriseCreateModel
  ) {
    if (impact.sousImpacts.length != 0) {
      impact.sousImpacts.forEach((e) => {
        this.deleteSousImpact(e, risque);
        this.deleteNiveauAlerte(e, risque);
      });
    }

    var ele: any = risque.niveauxAlertesList.find(
      (element: any) => element.libelle == impact.libelle
    );

    var indexOfUnSelect = risque.niveauxAlertesList.indexOf(ele) as any;
    if (indexOfUnSelect !== -1) {
      risque.niveauxAlertesList.splice(indexOfUnSelect, 1);
    }

    this.refreshIndex++;
  }

  onSousImpactSelect(sousImpacts: any, risque: RisqueEntrepriseCreateModel) {
    if (!(risque.niveauxAlertesList)) {
            risque.niveauxAlertesList = [];
        }
        var niveaualert: NiveauAlerteCreateModel = { valeur: null, impactId: sousImpacts.impactId, libelle: sousImpacts.libelle, sousImpactLibelle: sousImpacts.libelle, sousImpactId: sousImpacts.id }

        var isInArray = risque.niveauxAlertesList.includes(risque.niveauxAlertesList.find((e) => e.sousImpactLibelle == niveaualert.sousImpactLibelle) as any);

        if (!isInArray) {
            risque.sousImpactsToSave = [];
            risque.sousImpactsToSave.push(sousImpacts);
            risque.niveauxAlertesList.push(niveaualert);
        }
        this.refreshIndex++;
  }

  getSousImpactsList(
    impacts: ImpactEntrepriseCreateModel[],
    risque: RisqueEntrepriseCreateModel
  ) {
    // On nettoie la liste
    risque.sousImpacts = [];

    // Pour tout les impacts de la liste
    impacts.forEach((et: ImpactEntrepriseCreateModel) => {
      // Si impact a des sous impacts
      if (et.sousImpacts.length != 0) {
        // On prend les sous impacts et on les met dans la liste
        et.sousImpacts.forEach((e: SousImpactEntrepriseCreateModel) => {
          e.impactLibelle = et.libelle;
          risque.sousImpacts.push(e);
        });
        this.refreshIndex++;
      }
      // Le niveau d'alerte se place a ce niveau
      else {
        this.refreshIndex++;

        if (
          risque.niveauxAlertesList &&
          risque.niveauxAlertesList.length != 0
        ) {
          // DÃ©jÃ  un element dans la liste
          //On crÃ©er l'Ã©lement
          var niveau: NiveauAlerteEntrepriseCreateModel = {
            valeur: null,
            impactId: et.id,
            libelle: et.libelle,
          };

          // VÃ©rifier s'il n'est pas dedans
          var isList = risque.niveauxAlertesList.find(
            (lvl: NiveauAlerteEntrepriseCreateModel) =>
              lvl.impactId == niveau.impactId
          );

          // Si pas dedans on l'ajoute
          if (!isList) {
            risque.niveauxAlertesList.push(niveau);
          }
        } else {
          if (risque)
            if (!risque.niveauxAlertesList) {
              risque.niveauxAlertesList = [];
            }
          if (!(risque.niveauxAlertesList.length != 0)) {
            //On crÃ©er l'Ã©lement
            var niveau: NiveauAlerteEntrepriseCreateModel = {
              valeur: null,
              libelle: et.libelle,
              impactId: et.id,
            };
            risque.niveauxAlertesList.push(niveau);
          }
        }
      }
    });
  }

  async askDeleteRisque(risque: RisqueEntrepriseCreateModel) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer le risque ? ", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          var index = this.model.etudeDePoste!.risques?.indexOf(
            this.model.etudeDePoste!.risques.find(
              (e) => e.libelle == risque.libelle
            ) as any
          ) as any;

          this.model.etudeDePoste!.risquesIds = this.model.etudeDePoste!.risquesIds!.filter((x:any) => x != risque.id)

          risque.sousImpactsToSave = [];
          risque.valeursMesures = [];
          risque.impacts = [];
          risque.mesures.forEach((e) => (e.valeur = ""));
          risque.niveauxAlertesList = [];
          if (index !== -1) {
            this.model.etudeDePoste!.risques?.splice(index, 1);
          }
        } else {
          return;
        }
      });
  }

  isActiveClass(niveauAlert: any, valeur: number) {
    if (this.model.etudeDePoste!.niveauxAlertes?.some(n => 
      n.impactId == niveauAlert.impactId &&
      n.risqueId == niveauAlert.risqueId &&
      n.sousImpactId == niveauAlert.sousImpactId &&
      n.valeur == valeur
    )) return 'active'

    return ''
  }

  addNiveauAlert(
    niveau: NiveauAlerteEntrepriseCreateModel,
    value: any,
    element: any,
    risque: RisqueEntrepriseCreateModel
  ) {
    if (this.viewMode && !this.editMode) {
      return;
    }
    // Element avec dÃ©jÃ  une valeur -> ToSave
    if (
      element.target.closest(".icon-status-item").classList.contains("active")
    ) {
      element.target.closest(".icon-status-item").classList.remove("active");
      var indexOfNiveauDalerte = risque.niveauxAlertes.indexOf(
        risque.niveauxAlertes.find((e) => e.libelle == niveau.libelle) as any
      );
      if (indexOfNiveauDalerte !== -1) {
        risque.niveauxAlertes.splice(indexOfNiveauDalerte, 1);
      }
    }
    // Element sans valeur
    else {
      var parent = element.target.closest(".icon-list-custom");
      var icons = parent.querySelectorAll(".icon-status-item");
      icons.forEach((icon: any) => {
        icon.classList.remove("active");
      });
      element.target.closest(".icon-status-item").classList.toggle("active");
      switch (value) {
        case 1:
          niveau.valeur = NiveauAlerteValeur.Faible;
          break;
        case 2:
          niveau.valeur = NiveauAlerteValeur.Moyen;

          break;
        case 3:
          niveau.valeur = NiveauAlerteValeur.Important;

          break;

        default:
          break;
      }
      risque.niveauxAlertes = [];
      risque.niveauxAlertes.push(niveau);
    }
  }

  async loadRisquesRelativeData() {
    await this.$http.ressifnet.impactsEntreprise
      .getImpactsByRisques(this.model.etudeDePoste!.risques)
      .then(
        (response: PaginatedList<ImpactEntreprise>) => {
          
          this.model.etudeDePoste!.risques?.forEach((risque: any) => {

            if (!this.editMode) {
              if(!risque.impacts){
                risque.impacts = [];
              }
            }

            var impactsLoaded = response.items.filter(
              (impact: ImpactEntreprise) => impact.risqueId == risque.id
            );

            risque.impactsLibelle = impactsLoaded;

            this.loadImpactsList = true;
          });

          this.refreshIndex++;

        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des familles de risque",
          });
        }
      );
  }

  async loadTypesAction() {
    await this.$http.ressifnet.typesActionMilieuTravail
      .paginatedList(1, 50, true)
      .then((res: PaginatedList<TypeActionMilieuTravail>) => {
        this.listTypes = res;
      });
  }

  async loadPartiesPrenantes() {
    await this.$http.ressifnet.partiesPrenantesMilieuTravail
      .paginatedList(1, 100, true)
      .then((res: PaginatedList<PartiePrenanteMilieuTravail>) => {
        this.listPartiesPrenantes = res;
      });
  }

  async loadFamilleDeRisques() {
    await this.$http.ressifnet.famillesDeRisqueEntreprise
      .paginatedList(1, 50, true)
      .then(
        (response: PaginatedList<FamilleDeRisqueEntreprise>) => {
          this.famillesDeRisques = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des familles de risque",
          });
        }
      );
  }

  async searchBinomes(params?: any) {
    if (!params || params?.reset) {
      this.binomes = new PaginatedList();
    }

    await this.$http.ressifnet.binomesEntreprise
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<BinomeEntreprise>) => {
          this.binomes.items = this.binomes.items!.concat(
            response.items.filter(
              (ri) => !this.binomes.items!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des binomes",
          });
        }
      );
  }

  async searchPrescripteurs(params?: any) {
    if (!params || params?.reset) {
      this.prescripteurs = new PaginatedList();
    }

    await this.$http.ressifnet.prescripteursEntreprise
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<PrescripteurEntreprise>) => {
          this.prescripteurs.items = this.prescripteurs.items!.concat(
            response.items.filter(
              (ri) => !this.prescripteurs.items!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des prescripteurs",
          });
        }
      );
  }

  async searchContextes(params?: any) {
    if (!params || params?.reset) {
      this.contextes = new PaginatedList();
    }

    await this.$http.ressifnet.contextesEntreprise
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<ContexteEntreprise>) => {
          this.contextes.items = this.contextes.items!.concat(
            response.items.filter(
              (ri) => !this.contextes.items!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des contextes",
          });
        }
      );
  }

  async searchTypesDePoste(params: any) {
    if (!params || params?.reset) {
      this.typesDePoste = new PaginatedList();
    }

    await this.$http.ressifnet.typesDePosteEntreprise
      .paginatedList(
        this.secteurActiviteId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<TypeDePosteEntreprise>) => {
          this.typesDePoste.items = this.typesDePoste.items!.concat(
            response.items.filter(
              (ri) => !this.typesDePoste.items!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types de poste",
          });
        }
      );
  }

  async searchTags(params: any) {
    if (!params || params?.reset) {
      this.listeTags = this.listeTags.filter((e) =>
        this.model.etudeDePoste?.tagsId?.some((id) => id == e.id)
      );
      this.totalCountTags = this.listeTags.length;
    }

    await this.$http.ressifnet.tags
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search
      )
      .then((res: PaginatedList<Tag>) => {
        this.totalCountTags = res.totalCount;
        this.listeTags = this.listeTags!.concat(
          res.items.filter(
            (ri) => !this.listeTags!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async searchTagsManuel(params: any) {
    if (!params || params?.reset) {
      this.listeTagsManuel = this.listeTagsManuel.filter((e) =>
        this.model.etudeDePoste?.tagsId?.some((id) => id == e.id)
      );
      this.totalCountTagsManuel = this.listeTagsManuel.length;
    }

    await this.$http.ressifnet.tags
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search
      )
      .then((res: PaginatedList<Tag>) => {
        this.totalCountTagsManuel = res.totalCount;
        this.listeTagsManuel = this.listeTagsManuel!.concat(
          res.items.filter(
            (ri) => !this.listeTagsManuel!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  askResetModel() {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir annuler la création de cette action ?",
        {
          title: "Confirmation d'annulation",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.resetModel();
        } else {
          return;
        }
      });
  }

  resetModel() {
    // Clear a la main des risque idk why
    if(this.model.etudeDePoste){
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.niveauxAlertesList = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.impacts = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.valeursMesures = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.sousImpacts = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.sousImpactsToSave = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.niveauxAlertesList = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.impactsLibelle = []});
      this.model.etudeDePoste.risques?.map((r:any) =>{ r.mesures = []});
    }
    
    this.typeCodeSelected = TypeCodeActionMilieuTravail.ACTION_CLASSIQUE;
    this.nonEvalueStress = false;
    this.niveauAlertEmpty = false;
    this.branches = [];
    this.secteurs = [];
    this.regions = [];
    this.sites = [];
    this.tags = [];
    this.sitesManuel = [];
    this.dossiers = [];
    this.tagsManuel = [];
    this.canSelectSecteurs = false;
    this.fichiersUploaded = [];
    this.model = {
      id: undefined,
      actionClassique: {
        branchesId: [],
        sitesId: [],
        regionsId: [],
        secteursId: [],
        partiesPrenantesExternesId: [],
        partiesPrenantesInternesId: [],
        typeRequiringImpact: false,
        typeRequiringLabel: false,
      },
      etudeDePoste: {
        binomesId: [],
        contextesId: [],
        branchesId: [],
        regionsId: [],
        risques: [],
        secteursId: [],
        sitesId: [],
        stress: 1,
        typeDePosteId: undefined,
      },
      fichiers: [],
      dateAction: this.dateToday,
    };
  }

  changeScopeType() {
    this.canSelectSecteurs = !this.canSelectSecteurs;
    this.allEntreprise = !this.allEntreprise;
    this.branches = [];
    this.secteurs = [];
    this.regions = [];
    this.sites = [];
    this.tags = [];
    this.sitesManuel = [];
    this.dossiers = [];
    this.tagsManuel = [];
  }

  async downloadFile(fichier: any) {
    if (!fichier.id) return;
    else {
      await this.$http.ressifnet.fichiers.downloadFile(fichier.id).then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
    }
  }

  async populateImpactsList() {
    await this.$http.ressifnet.impactsEntreprise
      .getImpactsByRisques(this.model.etudeDePoste!.risques)
      .then(
        (response: PaginatedList<ImpactEntreprise>) => {
          this.refreshIndex++;
          this.model.etudeDePoste!.risques?.forEach((risque: any) => {
            var impactLoaded = response.items.filter(
              (impact: ImpactEntreprise) => impact.risqueId == risque.id
            );
            if (impactLoaded.length) {
              risque.impactsLibelle = impactLoaded;
            }
            this.loadImpactsList = true;
          });
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des familles de risque",
          });
        }
      );
  }

  populateEtudeDePosteData(action: any) {
    if (this.model.etudeDePoste && this.model.etudeDePoste.risques) {
      this.model.etudeDePoste.risques?.forEach((risque) => {
        var risquesImpactsLinked = [...risque.impacts];

        risque.impacts = [];

        risquesImpactsLinked.forEach((impact) => {
          var impactAlerte = action.etudeDePoste.niveauAlertes?.find(
            (alerte: any) => {
              return alerte.impactId === impact.id;
            }
          );

          if (impactAlerte) {
            risque.impacts.push(impact);
          }
        });

        this.populateImpactsList();

        risque.niveauxAlertesList = [];

        action.etudeDePoste.niveauAlertes?.forEach(
          (alerte: any, index: any) => {
            var niveauAlerte = {
              risqueId: alerte.risqueId,
              valeur: alerte.valeur,
              impactId: alerte.impactId,
              sousImpactId: alerte.sousImpactId,
              libelle: "",
            };

            // Vérification si l'impactId est présent
            if (alerte.impactId) {
              var impact = risque.impacts.find((imp: any) => {
                return imp.id === alerte.impactId;
              });

              if (impact) {
                niveauAlerte.libelle = impact.libelle;
              }
            }

            // Vérification si le sousImpactId est présent
            if (alerte.sousImpactId) {
              var sousImpact = risque.impacts.reduce((acc: any, imp: any) => {
                var sousImpact = imp.sousImpacts.find((simp: any) => {
                  return simp.id === alerte.sousImpactId;
                });
                return sousImpact ? sousImpact : acc;
              }, null);

              if (sousImpact) {
                niveauAlerte.libelle = sousImpact.libelle;
              }
            }

            if (risque.id == niveauAlerte.risqueId) {
              risque.niveauxAlertesList.push(niveauAlerte);
            }
            this.refreshIndex++;
          }
        );

        risque.impacts.forEach((impact: any) => {
          impact.sousImpactsLibelle = []; // Création du tableau "sousImpactLibelle"

          // Parcours des sous-impacts de l'impact actuel
          impact.sousImpacts.forEach((sousImpact: any) => {
            // Vérification si le sous-impact est présent à la fois dans risque.impacts.sousImpacts et dans niveauAlertes
            var sousImpactExiste = action.etudeDePoste.niveauAlertes?.some(
              (alerte: any) => {
                return alerte.sousImpactId === sousImpact.id;
              }
            );

            // Si le sous-impact existe, ajouter son libellé à sousImpactLibelle
            if (sousImpactExiste) {
              impact.sousImpactsLibelle.push(
                sousImpact as SousImpactEntreprise
              );
            }

            risque.sousImpacts = [...impact.sousImpacts];
          });
        });

        risque.mesures.forEach((mesure: any) => {
          var valeurMesure = this.model.etudeDePoste?.valeursMesures?.find(
            (vm) => {
              return vm.mesureLibelle === mesure.libelle;
            }
          );

          if (valeurMesure) {
            mesure.valeur = valeurMesure.valeur;
            mesure.selected = true;
          }
        });
      });
    }
    this.refreshIndex++;
  }

  async selectAction(id: any, mode?: any) {

    // Check d'ou vient le select pour mettre le bon titre / icon
    if (mode == "create") {
      this.formTitle = "Créer une nouvelle action";
      this.formIcon = "Plus";
      this.viewMode = false;
      this.editMode = false;
      this.createMode = true;
      this.clearModel = JSON.parse(JSON.stringify(this.initialModel));
    }else if (mode == "edit"){
      this.viewMode = false;
      this.editMode = true;
      this.createMode = false;
    }else if( mode == "view"){
      this.viewMode = true;
      this.editMode = false;
      this.createMode = false;
    }
    
    if (id == null || id == "0") {
      this.resetModel();
    } else {
      this.loading = true;
      await this.$http.ressifnet.actionsMilieuTravail
        .getById(this.$route.params.idEntreprise, id)
        .then(async (action: any) => {
          this.branches =
            (action.actionClassique?.branches ||
              action.etudeDePoste?.branches) ??
            [];
          this.regions =
            (action.actionClassique?.regions || action.etudeDePoste?.regions) ??
            [];
          this.secteurs =
            (action.actionClassique?.secteurs ||
              action.etudeDePoste?.secteurs) ??
            [];

          this.sites =
            (action.actionClassique?.sites || action.etudeDePoste?.sites) ?? [];

          this.dossiers = action.etudeDePoste?.dossiers || [];

          // Retrouve le code de l'action
          let typeCode = this.listTypes.items!.find((x: any) => x.id == action.typeId)!.code;
          
          // classique
          if (typeCode != TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {

            if (mode == "edit") {
              this.formTitle = "Editer une action";
              this.formIcon = "Edit2";
              this.viewMode = false;
              this.editMode = true;
            } else if (mode == "view") {
              this.formTitle = "Consulter une action";
              this.formIcon = "Eye";
              this.viewMode = true;
            }

            this.model.typeCode = TypeCodeActionMilieuTravail.ACTION_CLASSIQUE;
            this.typeCodeSelected =
              TypeCodeActionMilieuTravail.ACTION_CLASSIQUE;

            this.model = {
              typeCode: TypeCodeActionMilieuTravail.ACTION_CLASSIQUE,
              fichiers: action.actionClassique.fichiers ?? [],
              fichiersUploaded: [],
              actionClassique: {
                branchesId: action.actionClassique.branches?.map(
                  (x: any) => x.id
                ),
                secteursId: action.actionClassique.secteurs?.map(
                  (x: any) => x.id
                ),
                sitesId: action.actionClassique.sites?.map((x: any) => x.id),
                regionsId: action.actionClassique.regions?.map(
                  (x: any) => x.id
                ),
                duree: action.actionClassique.duree,
                dureeHorsVacation: action.actionClassique.dureeHorsVacation,
                nbEmployes: action.actionClassique.nbEmployes,
                libelle: action.actionClassique.libelle,
                partiesPrenantesExternesId:
                  action.actionClassique.partiesPrenantesExternes?.map(
                    (x: any) => x.id
                  ),
                partiesPrenantesId: action.actionClassique.partiesPrenantesId,
                partiesPrenantesInternesId:
                  action.actionClassique.partiesPrenantesInternes?.map(
                    (x: any) => x.id
                  ),
              },
              id: action.id,
              typeId: action.typeId,
              commentaire: action.commentaire,
              createdBy: action.createdBy,
              dateAction: action.dateAction,
              dateModification: action.dateModification,
              dateSaisie: action.dateSaisie,
              etudeDePoste: null,
            };
            this.allEntreprise =
              action.actionClassique.sites!.length == 0 ? true : false;
          }

          // Etude de poste
          else if (typeCode == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
            if (mode == "edit") {
              this.formTitle = "Editer une étude de poste";
              this.formIcon = "Edit2";
              this.viewMode = false;
              this.editMode = true;
              this.canSelectSecteurs = false;
            } else if (mode == "view") {
              this.formTitle = "Consulter une étude de poste";
              this.formIcon = "Eye";
              this.viewMode = true;
            }
            
            this.model.typeCode = TypeCodeActionMilieuTravail.ETUDE_DE_POSTE;
            this.typeCodeSelected = TypeCodeActionMilieuTravail.ETUDE_DE_POSTE;

            await this.$nextTick();

            this.model = {
              typeCode: TypeCodeActionMilieuTravail.ETUDE_DE_POSTE,
              fichiers: [],
              fichiersUploaded: [],
              actionClassique: null,
              id: action.id,
              typeId: action.typeId,
              commentaire: action.commentaire,
              createdBy: action.createdBy,
              dateAction: action.dateAction,
              dateModification: action.dateModification,
              dateSaisie: action.dateSaisie,
              etudeDePoste: {
                id: action.etudeDePoste.id,
                duree: action.etudeDePoste.duree,
                prescripteurId: action.etudeDePoste.prescripteurId,              
                contextesId: action.etudeDePoste.contextes?.map(
                  (x: any) => x.id
                ),
                binomesId: action.etudeDePoste.binomes?.map((x: any) => x.id),
                typeDePosteId: action.etudeDePoste.typeDePosteId,
                stress: action.etudeDePoste.stress,
                risques: action.etudeDePoste.risques,
                risquesIds: action.etudeDePoste.risques.map((x: any) => x.id),
                valeursMesures: action.etudeDePoste.valeurMesures,
                niveauxAlertes: action.etudeDePoste.niveauAlertes,

                branchesId: action.etudeDePoste.branches?.map((x: any) => x.id),
                secteursId: action.etudeDePoste.secteurs?.map((x: any) => x.id),
                sitesId: action.etudeDePoste.sites?.map((x: any) => x.id),
                regionsId: action.etudeDePoste.regions?.map((x: any) => x.id),
              },
            };

            if (!action.etudeDePoste.sites) this.allEntreprise = true;

            this.populateEtudeDePosteData(action);

            // setTimeout(() => {
            //   (this.$refs["slider"] as any).setIndex(
            //     action.etudeDePoste.stress
            //   );
            //   this.refreshIndex++
            // }, 500);
          }

          this.loading = false;
          this.fichiersUploaded = action.fichiers;

          if (
            !this.branches?.length &&
            !this.regions?.length &&
            !this.sites?.length &&
            !this.secteurs?.length
          ) {
            this.canSelectSecteurs = false;
          }

          this.canSelectSecteurs = true;

          this.clearModel = JSON.parse(JSON.stringify(this.model));
        });

        this.clearModel = JSON.parse(JSON.stringify(this.model));
    }

    this.loadEntreprisesParts = true;
    this.refreshIndex++;

    setTimeout(() => {
      let formCard = this.$refs["formCard"] as any;
      formCard.scrollIntoView({ behavior: "smooth" });
    }, 350);
  }

  validateform() {
    
    (this.$refs["formValidation"] as any)
      .validate()
      .then((result: any) => { console.log('res', result)})

  }

  async saveAction(callback: any) {

    if (
      this.model.etudeDePoste &&
      this.model.etudeDePoste.risques?.some((x) =>
        x.niveauxAlertesList.some(
          (x: NiveauAlerteCreateModel) => x.valeur == null
        )
      )
    ) {
      this.niveauAlertEmpty = true;
      return;
    }
    (this.$refs["formValidation"] as any)
      .validate()
      .then(async (result: boolean) => {
        if (!result) return;
        else {
          let config = {
            onUploadProgress(progressEvent: any) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              callback(percentCompleted);
              return percentCompleted;
            },
          };

          if ((this.model as any).id && (this.model as any).id != undefined) {
            this.updateAction(config, this.model);
          } else {
            this.createAction(config, this.model);
          }
        }
      });
  }

  getPartiesPrenantesInternes() {
    return this.listPartiesPrenantes.items.filter(
      (x) => x.typePartiePrenante === 0
    );
  }

  getPartiesPrenantesExternes() {
    return this.listPartiesPrenantes.items.filter(
      (x) => x.typePartiePrenante === 1
    );
  }

  async createAction(config: any, action: any) {
    this.model = {
      typeId: action.typeId,
      typeCode: this.listTypes.items!.find((x: any) => x.id == action.typeId)!
        .code,
      dateAction: action.dateAction,
      fichiers: action.fichiers,
      commentaire: action.commentaire,
      actionClassique: null,
      etudeDePoste: null,
    };

    if (this.typeCodeSelected === TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
      var risques: RisqueEntrepriseToSave[] = [];
      action.etudeDePoste!.risques?.forEach((risque: any) => {
        var risqueToPush: RisqueEntrepriseToSave = {};
        {
          (risqueToPush.id = risque.id),
            (risqueToPush.niveauxAlertes = risque.niveauxAlertes),
            (risqueToPush.valeursMesures = risque.valeursMesures);
        }
        risques.push(risqueToPush);
      });

      let etudeDePoste: CreateActionMilieuTravailEtudePosteModel = {
        binomesId: action.etudeDePoste.binomesId,
        contextesId: action.etudeDePoste.contextesId,
        stress: action.etudeDePoste.stress,
        typeDePosteId: action.etudeDePoste.typeDePosteId,
        prescripteurId: action.etudeDePoste.prescripteurId,
        duree: action.etudeDePoste.duree,
        risquesIds: action.etudeDePoste!.risques?.map((x: any) => x.id),
        niveauxAlertes: action.etudeDePoste.risques
          ?.map((r: any) =>
            r.niveauxAlertesList?.map((na: any) => ({
              valeur: na.valeur,
              impactId: na.impactId,
              sousImpactId: na.sousImpactId,
            }))
          )
          .filter((x: any) => x != undefined)
          .flatMap((x: any) => x),
        valeursMesures: action
          .etudeDePoste!.risques?.map((x: any) =>
            x.mesures.filter((x: any) => x.selected)
          )
          .flatMap((x: any) => x)
          .filter((x: any) => x != undefined)
          .map((x: any) => ({ mesureId: x.id, valeur: x.valeur })),

        branchesId: this.branches?.map((x) => x.id) ?? [],
        regionsId: this.regions?.map((r: Region) => r.id) ?? [],
        sitesId: this.sites?.map((s: Site) => s.id) ?? [],
        secteursId: this.secteurs?.map((s: Secteur) => s.id) ?? [],
        tagsId: this.tags?.map((t: Tag) => t.id) ?? [],
        dossiersId: this.dossiers?.map((d: Dossier) => d.id!) ?? [],
      };

      if (action.etudeDePoste.sites && action.etudeDePoste.sites!.length == 0) {
        etudeDePoste.allEntreprise = true;
      } else {
        etudeDePoste.allEntreprise = false;
      }
      this.model.etudeDePoste = etudeDePoste;
    } else {
      let actionClassique: CreateActionMilieuTravailClassiqueModel = {
        partiesPrenantesInternesId:
          action.actionClassique.partiesPrenantesInternes?.map(
            (x: any) => x.id
          ),
        partiesPrenantesExternesId:
          action.actionClassique.partiesPrenantesExternes?.map(
            (x: any) => x.id
          ),
        partiesPrenantesId: (
          action.actionClassique!.partiesPrenantesExternesId ?? []
        ).concat(action.actionClassique!.partiesPrenantesInternesId ?? []),

        libelle: action.actionClassique.libelle,
        duree: action.actionClassique.duree,
        dureeHorsVacation: action.actionClassique.dureeHorsVacation,
        nbEmployes: action.actionClassique.nbEmployes,
        allEntreprise:
          action.actionClassique.sites &&
          action.actionClassique.sites!.length == 0
            ? true
            : false,
        branchesId: this.branches?.map((x) => x.id) ?? [],
        regionsId: this.regions?.map((r: Region) => r.id) ?? [],
        sitesId: this.sites?.map((s: Site) => s.id) ?? [],
        secteursId: this.secteurs?.map((s: Secteur) => s.id) ?? [],
        typeRequiringImpact: undefined,
        typeRequiringLabel: undefined,
      };
      this.model.actionClassique = actionClassique;
    }

    await this.$http.ressifnet.actionsMilieuTravail
      .create(this.$route.params.idEntreprise, this.model, config)
      .then(
        (response: string) => {
          successAlert.fire({
            title: "Création d'une action",
            text: "Ajout effectué avec succès",
          });

          this.allEntreprise = false;
          this.$emit("element-added");
          this.resetModel();
          this.percentUpload = 0;
          (
            this.$refs.formValidation as InstanceType<typeof ValidationObserver>
          ).reset();
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
          this.percentUpload = 0;
        }
      );
  }

  async updateAction(config: any, action: any) {
    this.model = {
      typeId: action.typeId,
      typeCode: this.listTypes.items!.find((x: any) => x.id == action.typeId)!
        .code,
      dateAction: action.dateAction,
      fichiers: action.fichiers,
      commentaire: action.commentaire,
      actionClassique: null,
      etudeDePoste: action.etudeDePoste,
    };
    if (this.typeCodeSelected === TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
      this.model.etudeDePoste = this.getEtudeDePosteFormatted();
    } else {
      let actionClassique: CreateActionMilieuTravailClassiqueModel = {
        partiesPrenantesInternesId:
          action.actionClassique.partiesPrenantesInternes?.map(
            (x: any) => x.id
          ),
        partiesPrenantesExternesId:
          action.actionClassique.partiesPrenantesExternes?.map(
            (x: any) => x.id
          ),
        partiesPrenantesId: (
          action.actionClassique!.partiesPrenantesExternesId ?? []
        ).concat(action.actionClassique!.partiesPrenantesInternesId ?? []),

        libelle: action.actionClassique.libelle,
        duree: action.actionClassique.duree,
        dureeHorsVacation: action.actionClassique.dureeHorsVacation,
        nbEmployes: action.actionClassique.nbEmployes,
        branchesId: this.branches?.map((x) => x.id) ?? [],
        regionsId: this.regions?.map((r: Region) => r.id) ?? [],
        sitesId: this.sites?.map((s: Site) => s.id) ?? [],
        secteursId: this.secteurs?.map((s: Secteur) => s.id) ?? [],
        typeRequiringImpact: undefined,
        typeRequiringLabel: undefined,
      };
      this.model.actionClassique = actionClassique;
    }
    await this.$http.ressifnet.actionsMilieuTravail
      .put(this.$route.params.idEntreprise, action.id, this.model, config)
      .then(
        (response: string) => {
          this.percentUpload = 0;

          successAlert.fire({
            title: "Mise à jour d'une action",
            text: "Mise à jour effectuée avec succès",
          });

          this.$emit("element-added");
          this.resetModel();
          (this.$refs["formValidation"] as any).reset();
          this.selectAction(action.id, "view");
          this.clearModel = JSON.parse(JSON.stringify(this.initialModel));
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
          this.percentUpload = 0;
        }
      );
  }

  getEtudeDePosteFormatted() {
    var risques: RisqueEntrepriseToSave[] = [];
    this.model.etudeDePoste!.risques?.forEach((risque: any) => {
      var risqueToPush: RisqueEntrepriseToSave = {};
      {
        (risqueToPush.id = risque.id),
          (risqueToPush.niveauxAlertes = risque.niveauxAlertes),
          (risqueToPush.valeursMesures = risque.valeursMesures);
      }
      risques.push(risqueToPush);
    });
    return {
      etudeDePosteId: this.model.etudeDePoste?.id,
      duree: this.model.etudeDePoste!.duree,
      typeDePosteId: this.model.etudeDePoste!.typeDePosteId,
      prescripteurId: this.model.etudeDePoste!.prescripteurId,
      binomesId: this.model.etudeDePoste!.binomesId,
      contextesId: this.model.etudeDePoste!.contextesId,
      risquesIds: this.model.etudeDePoste!.risques?.map((x) => x.id),
      stress: this.model.etudeDePoste!.stress,
      niveauxAlertes: this.model
        .etudeDePoste!.risques?.map((r) =>
          r.niveauxAlertesList?.map((na: any) => ({
            valeur: na.valeur,
            impactId: na.impactId,
            sousImpactId: na.sousImpactId,
          }))
        )
        .filter((x) => x != undefined)
        .flatMap((x) => x),
      valeursMesures: this.model
        .etudeDePoste!.risques?.map((x) =>
          x.mesures.filter((x: any) => x.selected)
        )
        .flatMap((x: any) => x)
        .filter((x: any) => x != undefined)
        .map((x: any) => ({ mesureId: x.id, valeur: x.valeur })),
    };
  }

  async searchBranches(params: any) {
    if (params && params.search) {
      this.listBranches = [];
    }
    await this.$http.ressifnet.branches
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Branche>) => {
          this.listBranches = this.listBranches!.concat(
            response.items.filter(
              (ri) => !this.listBranches!.some((lsi: any) => lsi.id == ri.id)
            )
          );
          this.listBranches.map((x) =>
            this.listBranches.forEach((x) => x.libelle)
          );
          this.totalCountBranches = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des branches",
          });
        }
      );
  }

  async searchRegions(params: any) {
    if (params && params.search) {
      this.listRegions = [];
    }
    await this.$http.ressifnet.regions
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Region>) => {
          this.listRegions = this.listRegions!.concat(
            response.items.filter(
              (ri) => !this.listRegions!.some((lsi: any) => lsi.id == ri.id)
            )
          );
          this.listRegions.map((x) =>
            this.listRegions.forEach((x) => x.libelle)
          );
          this.totalCountRegions = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des régions",
          });
        }
      );
  }

  async countDossiers(params: any) {
    await this.$http.ressifnet.dossiers
      .search({
        ...this.searchDossierParams,
        pageNumber: params?.pageNumber ?? 1,
        pageSize: params?.pageSize ?? 10,
      })
      .then(
        (response: PaginatedList<Dossier>) => {
          this.totalCountDossiers = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des dossiers",
          });
        }
      );
  }

  async searchDossiers(params: any) {
    if (!params || params?.reset) {
      this.listDossiersManuel = this.listDossiersManuel.filter((e) =>
        this.dossiers?.some((id) => id == e.id)
      );
      this.totalCountDossiersManuel = this.listDossiersManuel.length;
    }

    await this.$http.ressifnet.dossiers
      .search({
        ...this.searchDossiersManuelParams,
        pageNumber: params?.pageNumber ?? 1,
        pageSize: params?.pageSize ?? 10,
      })
      .then(
        (response: PaginatedList<Dossier>) => {
          this.listDossiersManuel = this.listDossiersManuel!.concat(response.items.filter(
            (ri) =>
              !this.listDossiersManuel!.some((lsi: any) => lsi.id == ri.id)
          ));

          this.totalCountDossiersManuel = response.totalCount;

          // this.listDossiersManuel.map((x) =>
          //   this.listDossiersManuel.forEach((x) => x.nom)
          // );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des dossiers",
          });
        }
      );
  }

  async searchSites(params: any) {
    if (params && params.search) {
      this.listSites = [];
    }
    await this.$http.ressifnet.sites
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Site>) => {
          this.listSites = this.listSites!.concat(
            response.items.filter(
              (ri) => !this.listSites!.some((lsi: any) => lsi.id == ri.id)
            )
          );
          this.listSites.map((x) => this.listSites.forEach((x) => x.libelle));
          this.totalCountSites = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des sites",
          });
        }
      );
  }

  async searchSitesManuel(params: any) {
    if (params && params.search) {
      this.listSitesManuel = [];
    }
    await this.$http.ressifnet.sites
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Site>) => {
          this.listSitesManuel = this.listSitesManuel!.concat(
            response.items.filter(
              (ri) => !this.listSitesManuel!.some((lsi: any) => lsi.id == ri.id)
            )
          );
          this.listSitesManuel.map((x) =>
            this.listSitesManuel.forEach((x) => x.libelle)
          );
          this.totalCountSitesManuel = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des sites",
          });
        }
      );
  }

  async searchSecteurs(params: any) {
    if (params && params.search) {
      this.listSecteurs = [];
    }
    await this.$http.ressifnet.secteurs
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (res: PaginatedList<Secteur>) => {
          this.listSecteurs = this.listSecteurs!.concat(
            res.items.filter(
              (ri) => !this.listSecteurs!.some((lsi: any) => lsi.id == ri.id)
            )
          );
          this.listSecteurs.map((x) =>
            this.listSecteurs.forEach(
              (x) => (x.completeLabel = x.libelle + " (" + x.siteLibelle + ")")
            )
          );
          this.totalCountSecteurs = res.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des secteurs",
          });
        }
      );
  }

  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de l'action ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.model.fichiers = this.model
        .fichiers!.filter((f: any) => f.name != fichier.nom );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.actionsMilieuTravail
      .deleteFile(
        this.$route.params.idEntreprise,
        (this.model as any).id,
        fichier.id
      )
      .then((res: any) => {
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        (this.model.fichiers! as any[]).push({ id: fichier.id });
        this.fichiersUploaded = this.fichiersUploaded
          .filter((f: any) => f.nom != fichier.nom );
      });
  }

  // Only de edp au autre
  onTypeActionSelected(val: any) {
   
    if (val) {
      var type = this.listTypes.items.find((x) => x.id == val);


      if(type!.code == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE){
        var diff = this.deepDiff(this.modelClear.actionClassique, this.model.actionClassique);
        if (diff && Object.keys(diff).length != 0) {
          this.tempCodeSelected = type!.code;
          this.toggleSwitchTypeModal(diff);
          (this.$refs['formValidation'] as any).reset();
        }
        else {
          (this.$refs['formValidation'] as any).reset();
          this.keepCurrentModification();
        }
      }else if (this.lastTypeCode == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE && type!.code != this.typeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
        var diff = this.deepDiff(this.modelClear.etudeDePoste, this.model.etudeDePoste);
        if (diff && Object.keys(diff).length != 0) {
          this.tempCodeSelected = type!.code;
          this.toggleSwitchTypeModal(diff);
          (this.$refs['formValidation'] as any).reset();
        }
        else {
          (this.$refs['formValidation'] as any).reset();
          this.keepCurrentModification();
        }
      }

      // this.typeCodeSelected = type!.code;
      // if (this.typeCodeSelected == TypeCodeActionMilieuTravail.ETUDE_DE_POSTE && this.lastTypeCode != TypeCodeActionMilieuTravail.ETUDE_DE_POSTE) {
      //   var diff = this.deepDiff(this.modelClear.actionClassique, this.model.actionClassique);
      //   if (diff && Object.keys(diff).length != 0) {
      //     this.tempCodeSelected = type!.code;
      //     this.toggleSwitchTypeModal(diff);
      //     (this.$refs['formValidation'] as any).reset();
      //   }
      //   else {
      //     (this.$refs['formValidation'] as any).reset();
      //     this.keepCurrentModification();
      //   }
      // }

      this.lastTypeCode = type!.code;
    }
  }

  toggleSwitchTypeModal(diff: any) {
    let array = Object.values(diff)[0] as Object;
    this.errorList = Object.keys(array).length;
    this.$bvModal.show("typeActionSwitchDModal");
  }

  keepCurrentModification() {
    var type = this.listTypes.items.find((x) => x.id == this.model.typeId);
    this.typeCodeSelected = type!.code;
    this.typeRequiringImpact = type!.requiringImpact;
    this.typeRequiringDureeVacation = type!.requiringDureeVacation;
    this.typeRequiringLabel = type!.requiringLabel;    
    this.resetSearchParams();
  }

  // @Watch("typeCodeSelected")
  // onTypeCodeChanged(typeCodeSelected: any) {
    // if (!this.viewMode) {
    //   this.resetSearchParams();
    // }

  //   this.lastTypeCode = typeCodeSelected;
  // }

  deepDiff(obj1: any, obj2: any) {
    const diff: any = {};

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (!obj2.hasOwnProperty(key)) {
          continue; // Skip properties that exist in obj1 but not in obj2
        }

        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
              diff[key] = { obj1: obj1[key], obj2: obj2[key] };
            }
          } else {
            const nestedDiff = this.deepDiff(obj1[key], obj2[key]);
            if (Object.keys(nestedDiff).length > 0) {
              diff[key] = nestedDiff;
            }
          }
        } else if (obj1[key] !== obj2[key]) {
          diff[key] = { obj1: obj1[key], obj2: obj2[key] };
        }
      }
    }

    return diff;
  }

  get fichiers() {
    var files = [];
    if (this.model.fichiers!.length > 0) {
      files = this.model
        .fichiers!.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded ?? []);
  }
}
