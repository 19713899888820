






































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  BProgress,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Site } from "@/api/models/options/entreprises/site";
import { Region } from "@/api/models/options/entreprises/region";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { required } from "@validations";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import { CreateActionAdministratifModel } from "@/api/models/actionsAdministratif/actionAdministratif";
import { TypeActionAdministratif } from "@/api/models/actionsAdministratif/typeActionAdministratif";
import { PartiePrenanteAdministratif } from "@/api/models/actionsAdministratif/partiePrenanteAdministratif";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  name: "create-action-administratif",
  components: {
    BProgress,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    RessifnetDateInput,
    RessifnetQuillEditor,
    BSpinner,
    MultipleFileInput
  },
})
export default class CreateActionAdministratif extends Vue {
  required = required;
  loading = false;
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))

  formTitle: string = "Créer une nouvelle action";
  formIcon: string = "Plus";
  viewMode: boolean = false;
  editMode: boolean = false;
  createMode: boolean = false;

  initialModel: any


  listTypes: PaginatedList<TypeActionAdministratif> =
    new PaginatedList<TypeActionAdministratif>();

  listPartiesPrenantes: PaginatedList<PartiePrenanteAdministratif> =
    new PaginatedList<PartiePrenanteAdministratif>();

  model: CreateActionAdministratifModel = {
    id: undefined,
    branchesId: [],
    sitesId: [],
    regionsId: [],
    secteursId: [],
    fichiers: [],
    partiesPrenantesExternesId: [],
    partiesPrenantesInternesId: [],
    dateAction : this.dateToday,
    typeRequiringImpact: true,
    typeRequiringLabel: true,
    typeRequiringDureeVacation: true
  };

  clearModel : CreateActionAdministratifModel = {
    id: undefined,
    branchesId: [],
    sitesId: [],
    regionsId: [],
    secteursId: [],
    fichiers: [],
    partiesPrenantesExternesId: [],
    partiesPrenantesInternesId: [],
    dateAction : this.dateToday,
    typeRequiringImpact: true,
    typeRequiringLabel: true,
    typeRequiringDureeVacation: true
  };

  fichiersUploaded = [];

  canSelectSecteurs = true;
  percentUpload: number = 0;

  branches?: Branche[] = [];
  totalCountBranches: number = 0;

  sites?: Site[] = [];
  totalCountSites: number = 0;

  regions?: Region[] = [];
  totalCountRegions: number = 0;

  secteurs?: Secteur[] = [];
  totalCountSecteurs: number = 0;

  listBranches: Branche[] = []
  listSites: Site[] = []
  listRegions: Region[] = [] 
  listSecteurs: Secteur[] = []

  droitsEnum = DroitsEnum;
  get canEdit() {
    return !this.model.id || this.model.createdBy === this.$store.state.user.user.uid || this.$can(this.droitsEnum.ENTREPRISE_MANAG_TOTAL);
  }

  async created() {
    this.initialModel = JSON.parse(JSON.stringify(this.clearModel));
    await this.loadTypesAction()
    await this.loadPartiesPrenantes()
  }

  getModel(){
    return this.model;
  }

  async loadTypesAction() {
    await this.$http.ressifnet.typesActionAdministratif
      .paginatedList(1, 50, true)
      .then((res: PaginatedList<TypeActionAdministratif>) => {
        this.listTypes = res;
      });
  }

  async loadPartiesPrenantes() {
    await this.$http.ressifnet.partiesPrenantesAdministratif
      .paginatedList(1, 100, true)
      .then((res: PaginatedList<PartiePrenanteAdministratif>) => {
        this.listPartiesPrenantes = res;
      });
  }

  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  askResetModel() {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir annuler la création de cette action ?",
        {
          title: "Confirmation d'annulation",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.resetModel();
        } else {
          return;
        }
      });
  }

  resetModel() {
    this.canSelectSecteurs = true
    this.model = {
      id: undefined,
      branchesId: [],
      sitesId: [],
      regionsId: [],
      secteursId: [],
      fichiers: [],
      partiesPrenantesExternesId: [],
      partiesPrenantesInternesId: [],
      dateAction : this.dateToday,
      typeRequiringImpact: true,
      typeRequiringLabel: true,
      typeRequiringDureeVacation: true
    };
    this.fichiersUploaded = []
  }

  resetClearModel() {
    
    this.clearModel = {
      id: undefined,
      branchesId: [],
      sitesId: [],
      regionsId: [],
      secteursId: [],
      fichiers: [],
      partiesPrenantesExternesId: [],
      partiesPrenantesInternesId: [],
      dateAction : this.dateToday,
      typeRequiringImpact: true,
      typeRequiringLabel: true,
      typeRequiringDureeVacation: true
    }
  }

  changeScopeType() {
    this.canSelectSecteurs = !this.canSelectSecteurs;
    this.branches = [];
    this.secteurs = [];
    this.regions = [];
    this.sites = [];
  }

  async downloadFile(fichier:any) {
    if (!fichier.id)
      return;
    else {
      await this.$http.ressifnet.fichiersDossiers
        .downloadFile(fichier.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
    }
  }

  async selectAction(id: string, mode?: any) {
    if (mode == "create") {
      this.formTitle = "Créer une nouvelle action";
      this.formIcon = "Plus";
      this.viewMode = false;
      this.editMode = false;
      this.createMode = true;
      this.clearModel = JSON.parse(JSON.stringify(this.initialModel));
    }else if (mode == "edit"){
      this.viewMode = false;
      this.editMode = true;
      this.createMode = false;
    }else if( mode == "view"){
      this.viewMode = true;
      this.editMode = false;
      this.createMode = false;
    }

    if (id == null || id == '0') {
      this.resetModel()
    }
    else {

      if( JSON.stringify(this.clearModel) != JSON.stringify(this.model))
      {
        let answer = window.confirm('Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter la page ?');
        if(answer){
          this.loading = true;
          await this.$http.ressifnet.actionsAdministratif
            .getById(this.$route.params.idEntreprise, id)
            .then((action: any) => {
              if (mode == "edit") {
                this.formTitle = "Editer une action";
                this.formIcon = "Edit2";
                this.viewMode = false;
                this.editMode = true;
              } else if (mode == "view") {
                this.formTitle = "Consulter une action";
                this.formIcon = "Eye";
                this.viewMode = true;
              }

              this.model = {
                partiesPrenantesExternesId: action.partiesPrenantesExternes?.map(
                  (x: any) => x.id
                ),
                partiesPrenantesInternesId: action.partiesPrenantesInternes?.map(
                  (x: any) => x.id
                ),
                id: action.id,
                libelle: action.libelle,
                duree: action.duree,
                dureeHorsVacation: action.dureeHorsVacation,
                nbEmployes: action.nbEmployes,
                typeId: action.typeId,
                commentaire: action.commentaire,
                createdBy: action.createdBy,
                dateAction: action.dateAction,
                dateModification: action.dateModification,
                dateSaisie: action.dateSaisie,
                branchesId: action.branches.map((b: Branche) => b.id),
                regionsId: action.regions.map((r: Region) => r.id),
                sitesId: action.sites.map((s: Site) => s.id),
                secteursId: action.secteurs.map((s: Secteur) => s.id),
                fichiers: [],
                typeRequiringImpact: false,
                typeRequiringLabel: false,
                typeRequiringDureeVacation: false
              };
              this.loading = false

              if (!action.branches.length && !action.regions.length && !action.sites.length && !action.secteurs.length)
                this.canSelectSecteurs = false

              this.fichiersUploaded = action.fichiers;
              this.branches = action.branches;
              this.regions = action.regions;
              this.secteurs = action.secteurs;
              this.sites = action.sites;

              this.clearModel = JSON.parse(JSON.stringify(this.model));
            });
          }                
      }
      else {
        this.loading = true;
          await this.$http.ressifnet.actionsAdministratif
            .getById(this.$route.params.idEntreprise, id)
            .then((action: any) => {
              if (mode == "edit") {
                this.formTitle = "Editer une action";
                this.formIcon = "Edit2";
                this.viewMode = false;
                this.editMode = true;
              } else if (mode == "view") {
                this.formTitle = "Consulter une action";
                this.formIcon = "Eye";
                this.viewMode = true;
              }

              this.model = {
                partiesPrenantesExternesId: action.partiesPrenantesExternes?.map(
                  (x: any) => x.id
                ),
                partiesPrenantesInternesId: action.partiesPrenantesInternes?.map(
                  (x: any) => x.id
                ),
                id: action.id,
                libelle: action.libelle,
                duree: action.duree,
                dureeHorsVacation: action.dureeHorsVacation,
                nbEmployes: action.nbEmployes,
                typeId: action.typeId,
                commentaire: action.commentaire,
                createdBy: action.createdBy,
                dateAction: action.dateAction,
                dateModification: action.dateModification,
                dateSaisie: action.dateSaisie,
                branchesId: action.branches.map((b: Branche) => b.id),
                regionsId: action.regions.map((r: Region) => r.id),
                sitesId: action.sites.map((s: Site) => s.id),
                secteursId: action.secteurs.map((s: Secteur) => s.id),
                fichiers: [],
                typeRequiringImpact: false,
                typeRequiringLabel: false,
                typeRequiringDureeVacation: false
              };
              this.loading = false

              if (!action.branches.length && !action.regions.length && !action.sites.length && !action.secteurs.length)
                this.canSelectSecteurs = false

              this.fichiersUploaded = action.fichiers;
              this.branches = action.branches;
              this.regions = action.regions;
              this.secteurs = action.secteurs;
              this.sites = action.sites;

              this.clearModel = JSON.parse(JSON.stringify(this.model));
            });
          }
    }
    setTimeout(() => {
      let formCard = (this.$refs['formCard'] as any);
      formCard.scrollIntoView({ behavior: 'smooth' })
    }, 350)
  }

  async saveAction(callback: any) { 
    (this.$refs["formValidation"] as any)
      .validate()
      .then(async (result: boolean) => {
        if (!result) return;
        else {
          this.model.branchesId = this.branches?.map((x) => x.id) ?? [];
          this.model.sitesId = this.sites?.map((x) => x.id) ?? [];
          this.model.regionsId = this.regions?.map((x) => x.id) ?? [];
          this.model.secteursId = this.secteurs?.map((x) => x.id) ?? [];

          this.model.partiesPrenantesId = (this.model.partiesPrenantesExternesId ?? [])
            .concat(this.model.partiesPrenantesInternesId ?? [])
          let config = {
            onUploadProgress(progressEvent: any) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              callback(percentCompleted);
              return percentCompleted;
            },
          };

          if ((this.model as any).id && (this.model as any).id != undefined) {
            this.updateAction(config, this.model);
          } else {
            this.createAction(config, this.model);
          }
        }
      });
  }

  getPartiesPrenantesInternes() {
    return this.listPartiesPrenantes.items.filter(
      (x) => x.typePartiePrenante === 0
    );
  }

  getPartiesPrenantesExternes() {
    return this.listPartiesPrenantes.items.filter(
      (x) => x.typePartiePrenante === 1
    );
  }

  async createAction(config: any, action: any) {
    this.model = {
      partiesPrenantesInternesId: action.partiesPrenantesInternes?.map(
        (x: any) => x.id
      ),
      partiesPrenantesExternesId: action.partiesPrenantesExternes?.map(
        (x: any) => x.id
      ),
      partiesPrenantesId: action.partiesPrenantesId,
      typeId: action.typeId,

      libelle: action.libelle,
      duree: action.duree,
      dureeHorsVacation: action.dureeHorsVacation,
      nbEmployes: action.nbEmployes,

      fichiers: action.fichiers,
      commentaire: action.commentaire,
      dateAction: action.dateAction,
      branchesId: this.branches?.map((b: Branche) => b.id),
      regionsId: this.regions?.map((r: Region) => r.id),
      sitesId: this.sites?.map((s: Site) => s.id),
      secteursId: this.secteurs?.map((s: Secteur) => s.id),
      typeRequiringImpact: true,
      typeRequiringLabel: true,
      typeRequiringDureeVacation: true
    };
    await this.$http.ressifnet.actionsAdministratif
      .create(this.$route.params.idEntreprise, this.model, config)
      .then(
        (response: string) => {
          successAlert.fire({
            title: "Création d'une action",
            text: "Ajout effectué avec succès",
          });

          this.$emit("element-added");
          this.resetModel();
          this.percentUpload = 0;
          (this.$refs.formValidation as InstanceType<typeof ValidationObserver>).reset();
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
          this.percentUpload = 0;
        }
      );
  }

  async updateAction(config: any, action: any) {
    this.model = {
      partiesPrenantesInternesId: action.partiesPrenantesInternes?.map(
        (x: any) => x.id
      ),
      partiesPrenantesExternesId: action.partiesPrenantesExternes?.map(
        (x: any) => x.id
      ),
      ...action,
      branchesId: this.branches?.map((b: Branche) => b.id),
      regionsId: this.regions?.map((r: Region) => r.id),
      sitesId: this.sites?.map((s: Site) => s.id),
      secteursId: this.secteurs?.map((s: Secteur) => s.id),
    };
    await this.$http.ressifnet.actionsAdministratif
      .put(this.$route.params.idEntreprise, action.id, this.model, config)
      .then(
        (response: string) => {
          this.percentUpload = 0;

          successAlert.fire({
            title: "Mise à jour d'une action",
            text: "Mise à jour effectuée avec succès",
          });

          this.$emit("element-added");
          this.resetModel();
          (this.$refs["formValidation"] as any).reset();
          this.selectAction(action.id, 'view');
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
          this.percentUpload = 0;
        }
      );
  }

  async searchBranches(params: any) {
    if (params?.search){
      this.listBranches = []
    }
    await this.$http.ressifnet.branches
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Branche>) => {
          this.listBranches = this.listBranches!.concat(response.items.filter(ri => !this.listBranches!.some((lsi: any) => lsi.id == ri.id)))
          this.listBranches.map(x => this.listBranches.forEach(x => x.libelle ))
          this.totalCountBranches = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des branches",
          });
        }
      );
  }

  async searchRegions(params: any) {
    if (params?.search){
      this.listRegions = []
    }
    await this.$http.ressifnet.regions
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Region>) => {
          this.listRegions = this.listRegions!.concat(response.items.filter(ri => !this.listRegions!.some((lsi: any) => lsi.id == ri.id)))
          this.listRegions.map(x => this.listRegions.forEach(x => x.libelle ))
          this.totalCountRegions = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des régions",
          });
        }
      );
  }

  async searchSites(params: any) {
    if (params?.search){
      this.listSites = []
    }
    await this.$http.ressifnet.sites
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Site>) => {
          this.listSites = this.listSites!.concat(response.items.filter(ri => !this.listSites!.some((lsi: any) => lsi.id == ri.id)))
          this.listSites.map(x => this.listSites.forEach(x => x.libelle ))
          this.totalCountSites = response.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des sites",
          });
        }
      );
  }

  async searchSecteurs(params: any) {
    if (params?.search){
      this.listSecteurs = []
    }
    await this.$http.ressifnet.secteurs
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (res: PaginatedList<Secteur>) => {
          this.listSecteurs = this.listSecteurs!.concat(res.items.filter(ri => !this.listSecteurs!.some((lsi: any) => lsi.id == ri.id)))
          this.listSecteurs.map(x => this.listSecteurs.forEach(x => x.completeLabel = x.libelle + " (" + x.siteLibelle + ")"))
          this.totalCountSecteurs = res.totalCount;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des secteurs",
          });
        }
      );
  }

  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de l'action ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.model.fichiers = this.model
        .fichiers!.filter((f: any) => f.name != fichier.nom );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.actionsAdministratif
      .deleteFile(
        this.$route.params.idEntreprise,
        (this.model as any).id,
        fichier.id
      )
      .then((res: any) => {
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        this.fichiersUploaded = this.fichiersUploaded
          .filter((f: any) => f.nom != fichier.nom );
        this.$emit('element-added');
      });
  }


  onTypeActionSelected(val: any) {
    var type = this.listTypes.items.find((x) => x.id == val);
    this.model.typeRequiringImpact = type!.requiringImpact;
    this.model.typeRequiringLabel = type!.requiringLabel;
    this.model.typeRequiringDureeVacation = type!.requiringDureeVacation;
    (this.$refs["formValidation"] as any).validate();
  }

  get fichiers() {
    var files = [];

    if (this.model.fichiers!.length > 0) {
      files = this.model
        .fichiers!.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded ?? []);
  }
}
