
































import { PaginatedList } from "@/api/models/common/paginatedList";
import { BButton, BSpinner } from 'bootstrap-vue'
import { Component, Vue, Watch } from "vue-property-decorator";
import ListActionsAdministratif from "./_ListActions.vue";
import CreateActionAdministratif from "./_CreateAction.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Site } from "@/api/models/options/entreprises/site";

@Component({
  components: {
    ListActionsAdministratif,
    CreateActionAdministratif,
    BSpinner,
    BButton,
    SearchableVueSelect
  },
})
export default class AccueilAdministratif extends Vue {
  actionId : string = "0";
  siteId: string = "";

  searchSitesTotalCount = 0;
  searchSitesItems: Site[] = [];

  reloadList(){
    (this.$refs['listActions'] as any).loadActions()
  }

  selectAction(actionId: string,mode:string) {
    this.actionId = actionId;
    (this.$refs['createAction'] as any).selectAction(actionId, mode)
  }

  resetForm() {
    (this.$refs['createAction'] as any).resetModel()
    this.actionId = "0"
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.searchSitesTotalCount = 0;
      this.searchSitesItems = this.searchSitesItems?.filter(e => e.id.toString() == this.siteId);
    }
    
    await this.$http.ressifnet.sites
      .paginatedList(
        this.$route.params.idEntreprise,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Site>) => {
        this.searchSitesTotalCount = response.totalCount;
        this.searchSitesItems = this.searchSitesItems!.concat(response.items.filter(ri => !this.searchSitesItems!.some(lsi => lsi.id == ri.id)))
        //this.siteId = this.searchSitesItems.length === 1 ? this.searchSitesItems[0].id : "";
      });
  }

  @Watch("siteId")
  selectSite() {
    (this.$refs['listActions'] as any).loadActions(this.siteId)
    this.actionId = "0"
  }
}
